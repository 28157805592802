//State da tela de Pesquisa e cadastro de grupos

var INITIAL_STATE = {

  pesquisaPedidoItem: {
    idPedido: '',
    idEmpresa: '',
    idCliente: '',
    idUsuario: '',
    dataPedido: '',
    dataFinalizado: '',
    mesaComanda: '',
},

  pedidoPedido: {
      idPedido: '',
        idEmpresa: '',
        idCliente: '',
        idUsuario: '',
        idUsuarioVendedor: 0,
        nomeUsuarioVendedor: '',
        dataPedido: '',
        dataFinalizado: '',
        mesaComanda: '',
        mesaComandaVinculada: '',
        observacao: '',
        tipoVenda: '',
        tipoPedido: '',
        percentualTaxa: '',
        valorTaxa: 0,
        valorEntrega: 0,
        valorSubTotal: 0,
        valorDesconto: 0,
        valorAcrescimo: 0,
        valorParcialTotal: 0,
        valorTotalMenosParcial: 0,
        valorTotal: 0,
        pedidoAberto: '',
        clienteEnderecoEntrega: '',
        nfceCancelada: '',
        idNotaFiscal: '',
        numeroNfce: '',
        chaveNfce: '', 
        serieNfce: '',
        dataAutorizacaoNfce: '',
        status: '',
        nomeCliente: '',
        nomeUsuarioVenda: '',
        pedidoItem: [],
  },

  pedidoPedidoItem: {
    idPedidoItem: '',
    idPedido: '',
    idEmpresa: '',
    idProduto: '',
    dataItem: '',
    quantidade: '',
    valorItem: '',
    desconto: '',
    valorTotal: '',
    observacaoItem: '',
    impresso: '',
    produzido: '',
    alterado: '',
    status: '',
    produtoDescricao: '',
    imagemProduto: '',
    codigoProduto: '',  
  },

  pedidoPedidoFaturar: {
    idPedido: '',
      idEmpresa: '',
      idCliente: '',
      idUsuario: '',
      mesaComanda: '',
      mesaComandaVinculada: '',
      observacao: '',
      tipoVenda: '',
      tipoPedido: '',
      percentualTaxa: '',
      valorDesconto: 0,
      valorAcrescimo: 0,
      valorTaxa: 0,
      valorParcial: 0,
      valorEntrega: 0,
      valorSubTotal: 0,
      valorRestante: 0,
      valorTroco: 0,
      valorTotal: 0,
      valorPagoDigitado: 0,
      formaPagamento: '',
      enderecoEntrega: '',
      pagamentosParcial: [],
      pedidoItens: [],
},

pedidoFaturado: {
  faturado: 'N', //S = Pedido Faturado N = Não Faturado
},

pedidoPagamentoParcial: {
  idPagamentoParcial: '',
    idPedido: '',
    idUsuario: '',
    idForma: '',
    valorPago: '',
    nome: '',
    dataHora: '',
    status: '',
},

pesquisaPedidoEndereco:{
  idCliente: '',
  celular: '',
  nomeCliente: '',
},

//Armazena o Endereço de Entrega (Delivery)
pedidoEnderecoEntrega: {
    idEndereco: '',
    idCliente: '',
    nomeEntrega: '',
    cep: '',
    endereco: '',
    bairro: '',
    cidade: '',
    uf: '',
    numero: '',
    complemento: '',
    status: '',
},



  invalido: {
    mesaComanda: false,
  },

  invalidoItem: {
    quantidade: false,
    valorItem: false,
  },

  invalidoPagamentoParcial: {
    valorPago: false,
    nome: false,
  },

  invalidoEnderecoEntrega: {
    celular: false,
    nomeCliente: false,
  },

  //Dados de Retorno da NFCe Gerada (idNotaFiscal)
  notaFiscalRetornoNFCe: {
    idNotaFiscal: '',
    status: '',
    mensagem: '',
  },

  //
  abreModalImprimir: {
    abrirModal: '',
  },

  notaFiscalNFCe: {}, //Todos os Dados de Cupom Fiscal NFCe para Visualizar/Gerar PDF
  PEDIDOs: [], //Pedidos retornados na pesquisa 
  pedidoPedidoCopia: {},
  PEDIDOITENs: [],
  CAIXAVENDAs: [], //Venda realizadas no caixa que foi aberto (Fechamento de Caixa)
  PEDIDOENDERECOs: [], //Endereços de Entrega para selecionar (Delivery)
  VENDEDOREs: [],
}

export default function PEDIDO(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'PEDIDO':
          switch (action.type) {
              case 'PESQUISA':
                  return { ...state, pesquisaProduto: { ...state.pesquisaProduto, [action.campo]: action.value } }
              case 'PEDIDOITENs':
                  return { ...state, PEDIDOITENs: action.value }
              case 'CLEAN_SEARCH':
                  return {
                      ...state,
                      pesquisaProduto: {
                        idPedido: '',
                        idEmpresa: '',
                        idCliente: '',
                        idUsuario: '',
                        dataPedido: '',
                        dataFinalizado: '',
                        mesaComanda: '',                                                                          
                      },
                      PEDIDOITENs: []
                  }
              case 'INVALIDO':
                  return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
              case 'INVALIDO_ITEM':
                return { ...state, invalidoItem: { ...state.invalidoItem, [action.campo]: action.value } }  
              case 'INVALIDO_PAGAMENTO_PARCIAL':
                return { ...state, invalidoPagamentoParcial: { ...state.invalidoPagamentoParcial, [action.campo]: action.value } }  
              case 'INVALIDO_ENDERECO_ENTREGA':
                return { ...state, invalidoEnderecoEntrega: { ...state.invalidoEnderecoEntrega, [action.campo]: action.value } }    
              case 'PEDIDOs':
                return { ...state, PEDIDOs: action.value }
              case 'PEDIDO':  
                  return { ...state, pedidoPedido: { ...state.pedidoPedido, [action.campo]: action.value } }
              case 'PEDIDO_COPIA':
                return { ...state, pedidoPedidoCopia: state.pedidoPedido }
              case 'PEDIDO_ITEM':  
                return { ...state, pedidoPedidoItem: { ...state.pedidoPedidoItem, [action.campo]: action.value } }
              case 'PEDIDO_FATURAR':  
                return { ...state, pedidoPedidoFaturar: { ...state.pedidoPedidoFaturar, [action.campo]: action.value } }
              case 'PEDIDO_FATURADO':  
                return { ...state, pedidoFaturado: { ...state.pedidoFaturado, [action.campo]: action.value } }
              case 'PEDIDO_PAGAMENTO_PARCIAL':  
                return { ...state, pedidoPagamentoParcial: { ...state.pedidoPagamentoParcial, [action.campo]: action.value } }
              case 'CLEAN_PEDIDO':
                  return {
                      ...state,
                      pedidoPedido: {
                        idPedido: '',
                        idEmpresa: '',
                        idCliente: '',
                        idUsuario: '',
                        idUsuarioVendedor: 0,
                        nomeUsuarioVendedor: '',
                        dataPedido: '',
                        dataFinalizado: '',
                        mesaComanda: '',
                        mesaComandaVinculada: '',
                        observacao: '',
                        tipoVenda: '',
                        tipoPedido: '',
                        percentualTaxa: '',
                        valorTaxa: 0,
                        valorEntrega: 0,
                        valorSubTotal: 0,
                        valorDesconto: 0,
                        valorAcrescimo: 0,     
                        valorParcialTotal: 0,   
                        valorTotalMenosParcial: 0,        
                        valorTotal: 0,
                        pedidoAberto: '',
                        enderecoEntrega: '',
                        nfceCancelada: '',
                        status: '',     
                        nomeCliente: '',
                        nomeUsuarioVenda: '',                
                      }
                  }
              case 'CLEAN_PEDIDO_ITEM':
                return {
                    ...state,
                    pedidoPedidoItem: {
                      idPedidoItem: '',
                      idPedido: '',
                      idEmpresa: '',
                      idProduto: '',
                      dataItem: '',
                      quantidade: '',
                      valorItem: '',
                      desconto: '',
                      valorTotal: '',
                      observacaoItem: '',
                      impresso: '',
                      produzido: '',
                      alterado: '',
                      status: '',
                      produtoDescricao: '',
                      imagemProduto: '',
                      codigoProduto: '',                                    
                    }
                }
              case 'CLEAN_PEDIDO_FATURAR':
                return {
                    ...state,
                    pedidoPedidoFaturar: {
                      idPedido: '',
                      idEmpresa: '',
                      idCliente: '',
                      idUsuario: '',
                      mesaComanda: '',
                      mesaComandaVinculada: '',
                      observacao: '',
                      tipoVenda: '',
                      tipoPedido: '',
                      percentualTaxa: '',
                      valorDesconto: 0,
                      valorAcrescimo: 0,
                      valorTaxa: 0,
                      valorParcial: 0,
                      valorEntrega: 0,
                      valorSubTotal: 0,
                      valorRestante: 0,
                      valorTroco: 0,
                      valorTotal: 0,
                      valorPagoDigitado: 0,
                      formaPagamento: '',
                      enderecoEntrega: '',
                      pagamentosParcial: [],
                      pedidoItens: [],                                
                    }
                }
                case 'CLEAN_PEDIDO_PAGAMENTO_PARCIAL':
                  return {
                      ...state,
                      pedidoPagamentoParcial: {
                        idPagamentoParcial: '',
                        idPedido: '',
                        idUsuario: '',
                        idForma: '',
                        valorPago: '',
                        nome: '',
                        dataHora: '',
                        status: '',                                                    
                      }
                  }
  
              case 'CLEAN_INVALIDO':
                  return {
                      ...state,
                      invalido: {
                        mesaComanda: false,
                      }
                  }
              case 'CLEAN_INVALIDO_PAGAMENTO_PARCIAL':
                return {
                    ...state,
                    invalidoPagamentoParcial: {
                      valor: false,
                      nome: false,
                    }
                }
              case 'CLEAN_INVALIDO_ENDERECO_ENTREGA':
                return {
                    ...state,
                    invalidoEnderecoEntrega: {
                      celular: false,
                      nomeCliente: false,
                    }
                }  
              case 'CAIXA_VENDAS':
                return { ...state, CAIXAVENDAs: action.value }
              case 'PEDIDO_ENDERECOs':
                return { ...state, PEDIDOENDERECOs: action.value }
              case 'PESQUISA_PEDIDO_ENDERECO':  
                return { ...state, pesquisaPedidoEndereco: { ...state.pesquisaPedidoEndereco, [action.campo]: action.value } }
              case 'CLEAN_PESQUISA_PEDIDO_ENDERECO':
                return {
                    ...state,
                    pesquisaPedidoEndereco: {
                      idCliente: '',
                      celular: '',
                      nomeCliente: '',
                    }
                }
              case 'PEDIDO_ENDERECO_ENTREGA':  
                return { ...state, pedidoEnderecoEntrega: { ...state.pedidoEnderecoEntrega, [action.campo]: action.value } }
              case 'CLEAN_PEDIDO_ENDERECO':
                return {
                    ...state,
                    pedidoEnderecoEntrega: {
                      idEndereco: '',
                      idCliente: '',
                      nomeEntrega: '',
                      cep: '',
                      endereco: '',
                      bairro: '',
                      cidade: '',
                      uf: '',
                      numero: '',
                      complemento: '',
                      status: '',                                                    
                    }
                }       
              case 'VENDEDOREs':
                return { ...state, VENDEDOREs: action.value }
              case 'NOTA_NFCE_RETORNO':
                return { ...state, notaFiscalRetornoNFCe: { ...state.notaFiscalRetornoNFCe, [action.campo]: action.value } }
              case 'NOTA_NFCE':
                return { ...state, notaFiscalNFCe: action.value }
              case 'MODAL':  
                return { ...state, abreModalImprimir: { ...state.abreModalImprimir, [action.campo]: action.value } }
              default:
                  return { ...state }
          }
      default:
          return { ...state }
  }
}




