//State da tela de Pesquisa e cadastro de Grupo de Contas

var INITIAL_STATE = {

  pesquisaContaGrupo: {
      idContaGrupo: '',
      idEmpresa: '',
      descricaoGrupo: '',
      tipoGrupo: '',
  },

  cadastroContaGrupo: {
    idContaGrupo: '',
    idEmpresa: '',
    descricaoGrupo: '',
    tipoGrupo: '',
    status: '',
  },

  invalido: {
    descricaoGrupo: false,
  },

  CONTAGRUPOs: [],
  inicioCadastro: false,
  cadastroContaGrupoCopia: {},
}

export default function CONTA_GRUPO(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'CONTA_GRUPO':
          switch (action.type) {
            case 'PESQUISA':
                return { ...state, pesquisaContaGrupo: { ...state.pesquisaContaGrupo, [action.campo]: action.value } }
            case 'CONTAGRUPOs':
                return { ...state, CONTAGRUPOs: action.value }
            case 'CLEAN_SEARCH':
                return {
                    ...state,
                    pesquisaContaGrupo: {
                      idContaGrupo: '',
                      idEmpresa: '',
                      descricaoGrupo: '',
                      statusGrupo: '',                                                    
                    },
                    CONTAGRUPOs: []
                }
            case 'INVALIDO':
                return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
            case 'CONTA_GRUPO':
                return { ...state, cadastroContaGrupo: { ...state.cadastroContaGrupo, [action.campo]: action.value } }
            case 'INICIO_CADASTRO':
                return { ...state, inicioCadastro: action.value }
            case 'CONTA_GRUPO_COPIA':
                return { ...state, cadastroContaGrupoCopia: state.cadastroContaGrupoCopia }
            case 'CONTA_COPIA_STATUS':
                return { ...state, cadastroContaGrupoCopia: { ...state.cadastroContaGrupoCopia, status: action.value } }
            case 'CLEAN_CONTA_GRUPO':
                return {
                    ...state,
                    cadastroContaGrupo: {
                      idContaGrupo: '',
                      idEmpresa: '',
                      descricaoGrupo: '',
                      tipoGrupo: '',
                      status: '',                                                        
                    }
                }
            case 'CLEAN_INVALIDO':
                return {
                    ...state,
                    invalido: {
                        descricaoGrupo: false,
                    }
                }
              default:
                  return { ...state }
          }
      default:
          return { ...state }
  }
}