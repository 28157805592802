//State da tela de Pesquisa e cadastro de Contas

var INITIAL_STATE = {

  pesquisaConta: {
      idConta: '',
      idEmpresa: '',
      descricaoConta: '',
      statusConta: '',
  },

  cadastroConta: {
    idConta: '',
    idEmpresa: '',
    descricaoConta: '',
    principal: 'N',
    vendas: 'N',
    entrada: 'N',
    baixa: 'N',
    status: '',
  },

  invalido: {
    descricaoConta: false,
  },

  CONTAs: [],
  inicioCadastro: false,
  cadastroContaCopia: {},
}

export default function CONTA(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'CONTA':
          switch (action.type) {
            case 'PESQUISA':
                return { ...state, pesquisaConta: { ...state.pesquisaConta, [action.campo]: action.value } }
            case 'CONTAs':
                return { ...state, CONTAs: action.value }
            case 'CLEAN_SEARCH':
                return {
                    ...state,
                    pesquisaConta: {
                    idConta: '',
                    idEmpresa: '',
                    descricaoConta: '',
                    statusConta: '',                                    
                    },
                    CONTAs: []
                }
            case 'INVALIDO':
                return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
            case 'CONTA':
                return { ...state, cadastroConta: { ...state.cadastroConta, [action.campo]: action.value } }
            case 'INICIO_CADASTRO':
                return { ...state, inicioCadastro: action.value }
            case 'CONTA_COPIA':
                return { ...state, cadastroContaCopia: state.cadastroContaCopia }
            case 'CONTA_COPIA_STATUS':
                return { ...state, cadastroContaCopia: { ...state.cadastroContaCopia, status: action.value } }
            case 'CLEAN_CONTA':
                return {
                    ...state,
                    cadastroConta: {
                      idConta: '',
                      idEmpresa: '',
                      descricaoConta: '',
                      principal: 'N',
                      vendas: 'N',
                      entrada: 'N',
                      baixa: 'N',                                    
                      status: '',                                      
                    }
                }
            case 'CLEAN_INVALIDO':
                return {
                    ...state,
                    invalido: {
                        descricaoConta: false,
                    }
                }
              default:
                  return { ...state }
          }
      default:
          return { ...state }
  }
}




