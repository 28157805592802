var api = {
  //urlBaseChefe: "https://localhost:5530/",
  urlBaseChefe: "https://producao.ernetwork.com.br:5530/",
  urlIntegradorApi: "https://producao.ernetwork.com.br:5590/",
  
  //Urls do App
  //Usuário
  urlValidarToken: "usuario/validartoken/",
  urlCadastroUsuarioLoginPesquisa: "usuario/usuarionomeloginemail/",
  urlCadastroUsuarioLogin: "usuario/incluiralterar",
  urlCadastroUsuarioLoginGetIdUsuario: "usuario/usuarioget/",
  urlCadastroUsuarioLoginIncluir: "usuario/UsuarioInclusao",
  urlCadastroUsuarioLoginAlterar: "usuario/usuarioalteracao",
  urlCadastroUsuarioLoginAlterarStatus: "usuario/usuarioalterarstatus/",
  urlRetornarUsuariosEmpresa: "usuario/usuarioslistaget/",
  urlUsuarioRecuperarSenha: "usuario/recuperarsenha/",
  urlUsuarioAlterarSenha: "usuario/usuarioalterarsenha",
  urlUsuarioLogin: "login/Authenticate",
  urlUsuarioLiberarUmDia: "usuario/usuarioliberarlicencadia/",
  

  //Cliente
  urlCadastroClientePesquisa: "cliente/clientesempresa/",
  urlCadastroClientePesquisaCelularNome: "cliente/clientecelularnome/",
  urlCadastroClienteAlterarStatus: "cliente/clientealterarstatus/",
  urlCadastroClienteIncluirAlterar: "cliente/ClienteIncluir",
  urlCadastroClienteGetIdCliente: "cliente/clienteget/",
  urlCadastroClienteEnderecoAlterarStatus: "cliente/clienteenderecoalterarstatus/",
  urlCadastroClienteEnderecoCelular: "cliente/clienteenderecocelular/",
  urlPesquisarCEP: "cliente/cependerecoget/",


  //Fornecedor
  urlCadastroFornecedorPesquisa: "fornecedor/fornecedoresget/",
  urlCadastroFornecedorGet: "fornecedor/fornecedorget/",
  urlCadastroFornecedorIncluirAlterar: "fornecedor/fornecedorincluiralterar",

  //Produto
  urlCadastroProdutoGetGrupos: "produto/gruposget/",
  urlCadastroProdutoGetGrupoProdutos: "produto/grupoprodutos/",
  urlCadastroProdutoGetProduto: "produto/produtoget/",
  urlCadastroProdutoGetProdutosPesquisa: "produto/produtopesquisa/",
  urlCadastroProdutoGetNCMs: "nfce/ncmtodosget",
  urlCadastroProdutoIncluirAlterar: "produto/produtoincluiralterar",  
  

  //Grupos de Tributos
  urlCadastroGrupoTributacaoGetTributacaoTodas: "tributacao/tributacaogrupoget/",
  urlCadastroGrupoTributocaoGetTributacaoCadastro: "tributacaoadm/tributacaoadmget",
  urlCadastroGrupoTributocaoIdGrupoTributacao: "tributacao/tributacaogrupoidget/",
  urlCadastroGrupoTributoAlterarStatus: "tributacao/tributacaogrupoalterarstatus/",
  urlCadastroGrupoTributoIncluirAlterar: "tributacao/tributacaogrupoincluiralterar",

  //Configuracoes
  urlCadastroConfiguracaoGetConfiguracao: "empresa/empresaconfiguracaoget/",
  urlCadastroConfiguracaoIncluirAlterar: "empresa/empresaconfiguracaoincluiralterar",

  //Financerio (Contas)
  urlCadastroContaGetContas: "conta/contasget/",
  urlCadastroContaGetContaId: "conta/contaget/",
  urlCadastroContaIncluirAlterar: "conta/containcluiralterar",
  urlCadastroContaAlterarStatus: "conta/contaalterarstatus/",

  //Financerio (Conta Grupos)
  urlCadastroContaGrupoGetGrupos: "conta/contagruposget/",
  urlCadastroContaGrupoGetContaGrupoId: "conta/contagrupoget/",
  urlCadastroContaGrupoIncluirAlterar: "conta/contagrupoincluiralterar",
  urlCadastroContaGrupoAlterarStatus: "conta/contagrupoalterarstatus/",
  
  //Financerio (Conta Sub Grupos)
  urlCadastroContaSubGrupoGetSubGrupos: "conta/contasubgruposget/",
  urlCadastroContaSugGrupoGetContaSubGrupoId: "conta/contasubgrupoget/",
  urlCadastroContaSubGrupoIncluirAlterar: "conta/contasubgrupoincluiralterar",
  urlCadastroContaSubGrupoAlterarStatus: "conta/contasubgrupoalterarstatus/",

  //Meus Dados (Empresa)
  urlCadastroMeusDadosGetMeusDados: "empresa/empresaget/",
  urlCadastroMeusDadosAlterar: "empresa/empresaalterardados",

  //NFCe
  urlNFCeIncluir: "nfce/gerarnfceweb",
  urlNFCeRetornarNFCe: "nfce/retornarcupomnfce/",

  //Pedido de Vendas
  urlPedidoPesquisarMesaComanda: "pedido/mesacomandaget/",
  urlPedidoIncluirAlterar: "pedido/pedidoitensincluiralterar",
  urlPedidoEditarItem: "pedido/pedidoitensmanutencao",
  urlPedidoMesasComandasDelivery: "pedido/pedidosget/",
  urlPedidoContaImpressao: "pedido/pedidocontaimpressao",
  urlPedidoItensImpressao: "pedido/atualizaritensimpressaoproducao",
  urlPedidoTrocar: "pedido/pedidotroca/",
  urlPedidoExcluir: "pedido/pedidoexcluir",
  urlPedidoReabrir: "pedido/pedidoreabrir",
  urlPedidoFechar: "pedido/pedidofechar",
  urlPedidoContaItens: "pedido/pedidoitenscontaget/",
  urlPedidoPagamentoParcial: "pedido/pagamentoparcial",
  urlPedidoExcluirPagamentoParcial: "pedido/pagamentoparcialexcluir/",
  urlPedidoConsultaDataForma: "pedido/pedidosconsultadataforma/",
  
  //Caixa
  urlCaixaPesquisar: "caixa/retornarcaixasdata/",
  urlCaixaIncluirAlterar: "caixa/caixaincluiralterar",
  urlCaixaAlterarStatus: "caixa/alterarstatus/",
  urlCaixaFluxoSoma: "caixa/retornarcaixafluxosoma/",
  urlCaixaFluxoDataUsuario: "caixa/retornarcaixafluxodataUsuario/",
  urlCaixaFecharReabrir: "caixa/caixafecharreabrir",
  urlCaixaRetirada: "caixa/caixaretirada",
  urlCaixaRetiradasUsuario: "caixa/retornarcaixaretiradasusuario/",
  urlCaixaEnviarRetiradasImpressao: "caixa/marcarretiradasimpressao",
  urlCaixaRetornarRetiradasData: "caixa/retornarcaixaretiradadata/",
  urlCaixaRetornarCaixas: "caixa/retornarcaixas/",

  //Operações (ENTRADA ou SAÍDA)
  urlOperacoesGet: "operacao/retornaroperacao/",

  //Entrada
  urlEntradaPesquisar: "movimentacao/retornarmovimentodata/",
  urlEntradaGet: "movimentacao/retornarmovimentoid/",
  urlEntradaIncluirAlterar: "movimentacao/incluiralterarmovimentacao",
  urlEntradaAlterarRemoverItem: "movimentacao/alterarremoveritem",
  urlMovimentoReabrir: "movimentacao/reabrirmovimentacao/",
  urlMovimentoExcluir: "movimentacao/excluirmovimentacao/",
  

  urlEmpresas: "empresa/empresatodas/",
  urlPesqUsuarios: "usuariopesq/usuariosempresa/",
  urlPesqUsuarioCpf: "usuariopesq/usuariocpf/",
  urlPesqUsuarioNome: "usuariopesq/usuariosnome/",
  urlCadastroUsuarioPesquisa: "usuario/usuarionomeloginemail/",
  urlCadastroUsuario: "usuario/incluiralterar",
  urlCadastroUsuarioGetIdUsuario: "usuario/usuarioget/",
  urlCadastroUsuarioAtivarInativar: "usuario/usuarioativarinativar/",
  //Graficos
  urlGraficoDashboard: "grafico/graficodashboardget/",
};
export default api;
