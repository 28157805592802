
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertar } from "~/Util/alertar";
import ReactWizard from "~/components/Wizard/Wizard";
//import ReactTable from "react-table";
import ReactTable from "react-table-6";
import * as controllerConta from "~/controller/Cadastros/controllerConta"
import * as controllerContaGrupo from "~/controller/Cadastros/controllerContaGrupo"
import { Select } from "~/components/ErnComponets/ErnComponets";
import classNames from "classnames";
import ArrowTooltip from "~/components/Tooltip/ArrowTooltip";
import {
  Card,
  Col,
  Button,
  ButtonGroup,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Row,
  TabContent,
  TabPane,
  Modal,
  UncontrolledTooltip,
} from "reactstrap";

// wizard steps
import Step1 from "~/views/Cadastros/Financeiro/ContaGrupo/Cadastro/ContaGrupoCadastro.jsx";

var steps = [
  {
    stepName: "", //Nome do Step
    stepIcon: "tim-icons icon-notes",
    component: Step1,
    stepProps: {},
  },
];

const tipoGrupo = [
  { value: "G", label: "Gastos" },
  { value: "R", label: "Receitas" },
  { value: "T", label: "Todos" },
];

const idStore = "CONTA_GRUPO"

const GrupoContaPesquisa = () => {
  const notificacao = useSelector((state) => state.notificacao);
  const login = useSelector((state) => state.login);


  const { CONTAGRUPOs, cadastroContaGrupo, pesquisaContaGrupo } = useSelector((state) => state.ContaGrupo);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [horizontalTabs, setHorizontalTabs] = useState("pesquisar");

  const [dataTable, setDataTable] = useState([]);
  const [table, setTable] = useState(renderTable([]));
  const [tableVisible, setTableVisible] = useState(false);
  const [width, setWidht] = useState(window.innerWidth);
  

  useEffect(() => {
    setTable(renderTable());
  }, [dataTable, loading]);

  function renderTable() {
    return dataTable.map((obj, key) => {
      return {
        ...dataTable[key],
        actions: (
          <div className="actions-center">
            <Button
              className="btn-link btn-icon"
              color="info"
              id="tooltipEditar"
              size="sm"
              title=""
              type="button"
              onClick={() => {
                handleEditarCadastro(obj);
              }}
            >
              <UncontrolledTooltip
                delay={0}
                target="tooltipEditar"
              >
                Editar
              </UncontrolledTooltip>                              
              <i className="tim-icons icon-pencil" />
            </Button>
            {obj.statusGrupo === 'ATIVO' ?
              <Button
                className="btn-link btn-icon"
                color="danger"
                id="tooltipInativar"
                size="sm"
                title=""
                type="button"
                onClick={() => {
                  handleAtivarInativarCadastro(obj);
                }}
              >              
                <UncontrolledTooltip
                  delay={0}
                  target="tooltipInativar"
                  >
                  Inativar
                </UncontrolledTooltip>                              
                <i className="tim-icons  icon-simple-remove"/>
              </Button>
            :
              <Button
                className="btn-link btn-icon"
                color="success"
                id="tooltipAtivar"
                size="sm"
                title=""
                type="button"
                onClick={() => {
                  handleAtivarInativarCadastro(obj);
                }}
              >
                  <UncontrolledTooltip
                    delay={0}
                    target="tooltipAtivar"
                    >
                    Ativar
                  </UncontrolledTooltip>                              
                <i className="tim-icons icon-check-2"/>
              </Button>
            }       
          </div>
        ),
      };
    });
  }

  //Monta Dados para Gride
  function tableData(data) {
    return data.map((prop, key) => {
      return {
        id: key,
        idEmpresa: prop["idEmpresa"],
        idContaGrupo: prop["idContaGrupo"],
        descricaoGrupo: prop["descricao"],
        tipoGrupo: prop["tipoGrupo"],
        statusGrupo: prop["status"],
      };
    });
  }

  async function handleEditarCadastro(obj) {
    setLoading(true);
    if (!loading) {
        await controllerContaGrupo.GetContaGrupo(notificacao, dispatch, obj.idContaGrupo, () => {
            setHorizontalTabs("cadastrar")
            setInicioCadastro(true);
            dispatch({ idStore, type: 'CLEAN_INVALIDO' });

        });
        setLoading(false);
    }
  }

  async function handleAtivarInativarCadastro(obj) {
    setLoading(true);
    if (!loading) {
      var statusNovo = '1';
      if (obj.statusConta === 'ATIVO'){
        statusNovo = '9'
      }
      await controllerContaGrupo.AtivarInativar(notificacao, dispatch, obj.idContaGrupo, statusNovo, () => {
        handlePesquisar();

    });
    setLoading(false);

    }
  }

  //Se existir registro carrega e deixa a gride visivel
  useEffect(() => {
    setDataTable(tableData(CONTAGRUPOs));
    if (CONTAGRUPOs.length > 0) {
      setTableVisible(true);
    } else {
      setTableVisible(false);
    }
  }, [CONTAGRUPOs]);


  async function handlePesquisar() {
    if (pesquisaContaGrupo.tipoGrupo === ''){
      alertar(notificacao, 'br', "Informe o Tipo de Grupo.", 'warning', 'icon-alert-circle-exc', 3);
    }else{
      await controllerContaGrupo.Pesquisar(notificacao, dispatch, pesquisaContaGrupo, login.usuario.empresa, () => {
  
      });
    }
    
  }

  function handleLimpar() {
    dispatch({ idStore, type: "CLEAN_SEARCH" });
    
  }

  function changeActiveTab(tabName) {
    setHorizontalTabs(tabName);
    if (tabName === "pesquisar") {
      setTimeout(() => {
        document.getElementById("descricaoGrupo").focus();
      }, 100);
    }
  }

  async function novoCadatro() {
    setInicioCadastro(true);
    dispatch({ idStore, type: "CLEAN_CONTA_GRUPO" });
    dispatch({ idStore, type: "CLEAN_INVALIDO" });
  }

  function setInicioCadastro(valor) {
    dispatch({ idStore, type: "INICIO_CADASTRO", value: valor });
  }

  function handleNovoCadastro(){
      changeActiveTab("cadastrar");
      novoCadatro();
      dispatch({ idStore, type: 'CONTA_GRUPO', campo: 'status', value: '1' })
  }

  useEffect(() => {
    steps.forEach((step) => {
      step.stepProps = { ...step.stepProps, notificacao };
    });
  }, [notificacao]);

  useEffect(() => {
    steps.forEach((step) => {
      step.stepProps = { ...step.stepProps, cadastroContaGrupo: cadastroContaGrupo };
    });
    //setInicioCadastro(false);
  }, [cadastroContaGrupo]);

/*   useEffect(() => {
    if (inicioCadastro) setInicioCadastro(false);
  }, [inicioCadastro]);
 */

  function handleOnChange(value, campo) {
    dispatch({ idStore, type: "PESQUISA", campo, value });
  }

  function onChangeCombo(value) {
    dispatch({ idStore, type: "PESQUISA", campo: "tipoGrupo", value });
    //setpesquisa({ ...pesquisa, situacao: value })
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      //controllerPesqUsuario.Pesquisar(notificacao, dispatch, pesquisaLogin);
    }
  }


  function updateWidth() {
    setWidht(window.innerWidth);
  }


  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    dispatch({ idStore, type: "PESQUISA", campo: "tipoGrupo", value: "T" });
  }, []);

  return (
    <>
      <div className="content">
        <Col className="mr-auto ml-auto" md="12">
          <Card>
             <CardHeader className="text-center">
              <CardTitle tag="h4">
               Grupo de Contas (Plano de Contas)
              </CardTitle>
              <TabContent
                className="tab-space"
                activeTab={horizontalTabs}
                style={{ marginTop: "-30px", marginBottom: "-70px" }}
              >
                 <TabPane tabId="pesquisar">
                  <h5>Pesquisa de Grupos de Conta</h5>
                </TabPane> 

                 <TabPane tabId="cadastrar">
                  <h5>Cadastro de Grupo de Conta</h5>
                </TabPane> 
              </TabContent>
            </CardHeader>

            <CardBody>
              <ButtonGroup style={{ width: "100%" }}>
                <ArrowTooltip title="shift+1">
                  <Button
                    color={horizontalTabs === "pesquisar" ? "info" : ""}
                    type="button"
                    data-toggle="tab"
                    className={classNames(
                      "btn-gp-padrao",
                      horizontalTabs === "pesquisar" ? "active" : ""
                    )}
                    onClick={() => {
                      changeActiveTab("pesquisar");
                    }} 
                    style={{ width: "50%" }}
                  >
                    Pesquisar
                  </Button>
                </ArrowTooltip>
                 <ArrowTooltip title="shift+2">
                  <Button
                    color={horizontalTabs === "cadastrar" ? "info" : ""}
                    type="button"
                    data-toggle="tab"
                    className={classNames(
                      "btn-gp-padrao",
                      horizontalTabs === "cadastrar" ? "active" : ""
                    )}
                    onClick={() => {
                      changeActiveTab("cadastrar");
                      novoCadatro();
                    }}
                    style={{ width: "50%" }}
                  >
                    Cadastrar
                  </Button>
                </ArrowTooltip>
              </ButtonGroup>
            </CardBody>
            <CardBody>

            </CardBody>
            <TabContent
              className="tab-space"
              activeTab={horizontalTabs}
              style={{ paddingBottom: "0px" }}
            >
              <TabPane tabId="pesquisar">
                {/* INCIO TabPesquisa-GRADE */}
                <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col" lg="2" md="3">
                    <label>Código</label>
                    <Input
                      id="idContaGrupo"
                      type="text"
                      autoComplete="off"
                      value={pesquisaContaGrupo.idContaGrupo}
                      onChange={(e) =>
                        handleOnChange(e.target.value, e.target.id)
                      }
                      onKeyPress={(e) => handleKeyPress(e)}
                      maxLength="15"
                    />
                  </Col>                  

                  <Col className="padding-padrao-col" lg="6" md="3">
                    <label>Descrição</label>
                    <Input
                      id="descricaoGrupo"
                      type="text"
                      autoComplete="off"
                      value={pesquisaContaGrupo.descricaoGrupo}
                      onChange={(e) =>
                        handleOnChange(e.target.value, e.target.id)
                      }
                      onKeyPress={(e) => handleKeyPress(e)}
                      maxLength="30"
                    />
                  </Col>         

                  <Col className="padding-padrao-col" lg="3" md="3">
                    <label>Tipo de Grupo</label>
                    <Select
                      id="tipoGrupoSelect"
                      value={{ value: pesquisaContaGrupo.tipoGrupo }}
                      options={tipoGrupo}
                      onChange={(value) =>
                        onChangeCombo(value.value)
                      }  
                      autoComplete="off"
                    />
                  </Col>

                </Row>

                <Row>
                  <CardBody>
                    <Col style={{ width: "100%" }}>
                      <ArrowTooltip title="Limpar">
                        <Button
                          color="info"
                          style={
                            width > 550
                              ? { float: "right", width: "150px" }
                              : width < 550
                                ? { marginLeft: "0px", width: "100%" }
                                : {}
                          }
                          onClick={() => handleLimpar()}
                        >
                          Limpar
                        </Button>
                      </ArrowTooltip>
                      <ArrowTooltip title="Pesquisar">
                        <Button
                          color="info"
                          style={
                            width > 550
                              ? { float: "right", width: "150px" }
                              : width < 550
                                ? { marginLeft: "0px", width: "100%" }
                                : {}
                          }
                          onClick={() => handlePesquisar()}
                        >
                          Pesquisar
                        </Button>
                      </ArrowTooltip>
                      <ArrowTooltip title="Novo Cadastro">
                        <Button
                          color="warning"
                          style={
                            width > 550
                              ? { float: "right", width: "150px" }
                              : width < 550
                                ? { marginLeft: "0px", width: "100%" }
                                : {}
                          }
                          onClick={() => handleNovoCadastro()}
                        >
                          Novo
                        </Button>
                      </ArrowTooltip>                    
                    </Col>
                  </CardBody>
                </Row>
                <div
                  style={
                    tableVisible
                      ? {
                          transition: "0.5s ease-in-out",
                          visibility: "visible",
                          opacity: "1",
                        }
                      : { visibility: "hidden", opacity: "0" }
                  }
                >
                  <ReactTable
                    data={table}
                    filterable={false}
                    columns={[
                      {
                        Header: "id Grupo",
                        accessor: "idContaGrupo",
                      },
                      {
                        Header: "Descrição",
                        accessor: "descricaoGrupo",
                      },
                      {
                        Header: "Tipo de Grupo",
                        accessor: "tipoGrupo",
                      },
                      {
                        Header: "Status",
                        accessor: "statusGrupo",
                      },
                     {
                        Header: "",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    minRows={1}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    noDataText=""
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando"
                    pageText="Página"
                    ofText="de"
                    rowsText="linhas"
                  />
                </div>
                {/* FIM TabPesquisa-GRADE */}
              </TabPane>
              {/* FIM TabPesquisa */}
              {/* INICIO TabCadastro */}
              <TabPane tabId="cadastrar">
                <div style={{ marginTop: "-70px" }}>
                   <ReactWizard
                    steps={steps}
                    //navSteps={true}
                    title=""
                    description=""
                    validate
                    resizable={true}
                    previousButtonText="Voltar"
                    finishButtonText="Finalizar"
                    nextButtonText="Avançar"
                    //goToFirstStep={inicioCadastro}
                    //finishButtonClick={() => finishButtonClick()}
                      finishButtonClasses={classNames(
                      "btn-wd btn-info invisible",
                      {
                        finishButtonMin: width < 550,
                      },
                      {
                        finishButtonRight: !width < 550,
                      }
                    )} 
                     nextButtonClasses={classNames(
                      "btn-wd btn-info",
                      {
                        nextButtonMin: width < 550,
                      },
                      {
                        nextButtonRight: !width < 550,
                      }
                    )}
                    previousButtonClasses={classNames(
                      "btn-wd",
                      {
                        previousButtonMin: width < 550,
                      },
                      {
                        previousButtonRight: !width < 550,
                      }
                    )}
                    progressbar
                    color="blue"
                    wizardNavigationVisivel={false} //Para não mostrar a barra de navegação do wizard
                  />
                </div>
              </TabPane>
            </TabContent>
            {/* FIM TabCadastro */}
          </Card>
        </Col>
      </div>
    </>
  );
};

export default GrupoContaPesquisa;
