import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts'
import {maskMoeda, maskNumbers} from "~/Util/mascaras";

pdfMake.vfs = pdfFonts.vfs;

//---------------------------------------------------------------------------
// Impressão de Cupom Fiscal NFC-e
// tipoImpressao = V (Visualizar em outra Aba)
// tipoImpressao = G (Gerar Pdf para Baixar)
//---------------------------------------------------------------------------
export const pdfCupomNFCe = (login, tipoImpressao, notaFiscalNFCe) => {
  
  //----------------------------------------------------------------------
  // NFC-e
  //----------------------------------------------------------------------
  var linhaProdutos = notaFiscalNFCe.notaItens;
  var notaPagamentos = notaFiscalNFCe.notaPagamentos;
  let pagamentos = [];
  for (var i = 0; i < notaPagamentos.length; i++) {
    if (notaPagamentos[i].tipoPagamento === '01'){
      notaPagamentos[i].tipoPagamento = 'DINHEIRO';
    }else if (notaPagamentos[i].tipoPagamento === '02'){
      notaPagamentos[i].tipoPagamento = 'PIX';
    } else if (notaPagamentos[i].tipoPagamento === '03'){
      notaPagamentos[i].tipoPagamento = 'CARTÃO DÉBITO';
    } else if (notaPagamentos[i].tipoPagamento === '04'){
      notaPagamentos[i].tipoPagamento = 'CARTÃO CRÉDITO';
    } else if (notaPagamentos[i].tipoPagamento === '05'){
      notaPagamentos[i].tipoPagamento = 'LANÇ. FUTURO';
    }
    pagamentos.push(notaPagamentos[i]);
  }

  var consumidorNFCe = 'CONSUMIDOR NÃO IDENTIFICADO'
  const nomeConsumidor = notaFiscalNFCe.destinatarioNome; 
  if (nomeConsumidor !== '***CONSUMIDOR***'){
    consumidorNFCe = 'CONSUMIDOR:  ' + notaFiscalNFCe.destinatarioNome;
    if (nomeConsumidor.length === 11){
      consumidorNFCe += '   CPF:  ' + notaFiscalNFCe.destinatarioCpfCnpj;
    }else{
      consumidorNFCe += '   CNPJ:  ' + notaFiscalNFCe.destinatarioCpfCnpj;
    }
  }

  var cabecalhoHeight = 100;
  var itensHeight = 200;
  var valoresHeight = 100;
  var dadosNfceHeight = 200
  var infoChefeHeight = 30;

  if (linhaProdutos.length > 10){
    var itens = linhaProdutos.length - 10;
    itensHeight = itensHeight + itens * 30;
  }

  var pageSizeHeight = cabecalhoHeight + itensHeight + valoresHeight + dadosNfceHeight + infoChefeHeight;

  const docDefinition = {
    pageSize: {
      width: 260,
      height: pageSizeHeight,
    },
    content: [
      {
        alignment: 'center',
        text: login.usuario.nomeEmpresa,
        style: 'header'
      },
      {
        alignment: 'center',
        text: [ notaFiscalNFCe.emitenteLogradouro + ' - ' + notaFiscalNFCe.emitenteNumero + '\n',
        notaFiscalNFCe.emitenteBairro + ' ' + notaFiscalNFCe.emitenteMunicipio + '-' + notaFiscalNFCe.emitenteUf + '\n',
        'Nosso Número: ' + notaFiscalNFCe.emitenteFone + '\n\n',
        ],
        style: 'small'
      },
      {
        alignment: 'center',
        text: 'DANFE NFC-e - Documento Auxiliar da Nota Fiscal Eletrônica para o Consumidor Final\n\n',
        style: 'subheader'
      },

      //---------------------------------------------------------------
      // Tabela com Itens do NFC-e - Gerando Dinamicamente
      //---------------------------------------------------------------
      {
        style: 'styleProduto',
        layout: 'headerLineOnly', //Apenas linha de grade abaixo do cabeçalho
        table: {
          headerRows: 1, // Número de linhas de cabeçalho
          widths: [90, 15, 15, 30, 30], // Larguras das colunas
          body: [
            // Cabeçalho da tabela
            ['Produto', {text: 'Qtd.', alignment: 'center'}, {text: 'Und.', alignment: 'center'}, {text: 'Valor', alignment: 'right'}, {text: 'Total', alignment: 'right'}],
            // Produtos (Linha a Linha)
             ...linhaProdutos.map(linhaProdutos => [{text: linhaProdutos.descricao, colSpan: 1, alignment: 'left'},
                                                    {text: linhaProdutos.quantidade, alignment: 'center'}, 
                                                    {text: linhaProdutos.unidade, alignment: 'center'}, 
                                                    {text: maskMoeda(linhaProdutos.valorUnitario.toFixed(2)), alignment: 'right'},
                                                    {text: maskMoeda(linhaProdutos.valorTotal.toFixed(2)), alignment: 'right'},
                                                  ],),  
 
          ],         
        },
      },
      //--- Fim Itens -----------------

      // 
      {
        alignment: 'left',
        text: '\n', //Linha em BRANCO 
        style: 'subheader'
      },

      // Linha para separação
      {
        style: 'styleLinha',
        canvas: [
          {
            type: 'line', // Tipo de elemento: linha
            x1: 10,        // Ponto inicial no eixo X
            y1: 0,        // Ponto inicial no eixo Y
            x2: 255,      // Ponto final no eixo X
            y2: 0,        // Ponto final no eixo Y
            lineWidth: 1, // Largura da linha
            lineColor: 'black', // Cor da linha
          },
        ],
      },      

      {
        alignment: 'left',
        text: '\n',
        style: 'subheader'
      },

      //----------------------------------------------------------------------
      // Valores do Cupom
      //----------------------------------------------------------------------
      {
        style: 'styleValoresTotais',
        columns: [
          {
            alignment: 'left',
            width: 120,
            text: 'Quantidade total de itens'
          },
          {
            alignment: 'right',
            width: 120,
            text: linhaProdutos.length
          }
        ]  
      },

      {
        style: 'styleValoresTotais',
        columns: [
          {
            alignment: 'left',
            width: 120,
            text: 'Valor Desconto'
          },
          {
            alignment: 'right',
            width: 120,
            text: maskMoeda(notaFiscalNFCe.valorDesconto.toFixed(2)),
          }
        ]  
      },

      {
        style: 'styleValoresTotais',
        columns: [
          {
            alignment: 'left',
            width: 120,
            text: 'Valor Acrescimo'
          },
          {
            alignment: 'right',
            width: 120,
            text: maskMoeda(notaFiscalNFCe.valorAcrescimo.toFixed(2)),
          }
        ]  
      },

      {
        style: 'styleValoresTotais',
        columns: [
          {
            alignment: 'left',
            width: 120,
            text: 'Valor Total'
          },
          {
            alignment: 'right',
            width: 120,
            text: maskMoeda(notaFiscalNFCe.valorTotal.toFixed(2)),
          }
        ]  
      },

      {
        alignment: 'left',
        text: '\n',         //Linha em BRANCO
        style: 'subheader'
      },

      // Linha para separação
      {
        style: 'styleLinha',
        canvas: [
          {
            type: 'line', // Tipo de elemento: linha
            x1: 10,        // Ponto inicial no eixo X
            y1: 0,        // Ponto inicial no eixo Y
            x2: 250,      // Ponto final no eixo X
            y2: 0,        // Ponto final no eixo Y
            lineWidth: 1, // Largura da linha
            lineColor: 'black', // Cor da linha
          },
        ],
      },

      {
        alignment: 'left',
        text: '\n',         //Linha em BRANCO
        style: 'subheader'
      },

      {
        style: 'styleFormaPagamento',
        columns: [
          {
            alignment: 'left',
            width: 120,
            text: 'FORMA PAGAMENTO'
          },
          {
            alignment: 'right',
            width: 120,
            text: 'VALOR PAGO',
          }
        ]  
      },
      //--- Fim Valores -------------------

      //---------------------------------------------------------------
      // Tabela com valores Pago - Gerando o conteúdo dinamicamente
      //---------------------------------------------------------------
      {
        style: 'styleFormaPagamento',
        layout: 'headerLineOnly',
        table: {
          headerRows: 1, // Número de linhas de cabeçalho
          widths: [120, 105], // Larguras das colunas
          body: [
             ...pagamentos.map(pagamentos => [{text: pagamentos.tipoPagamento, alignment: 'left'},
                                              {text: maskMoeda(pagamentos.valorPago.toFixed(2)), alignment: 'right'}, 
                                                  ],),   
          ],         
        },
      },

      {
        alignment: 'left',
        text: '\n',         //Linha em BRANCO
        style: 'subheader'
      },

      // Linha para separação
      {
        style: 'styleLinha',
        canvas: [
          {
            type: 'line', // Tipo de elemento: linha
            x1: 10,        // Ponto inicial no eixo X
            y1: 0,        // Ponto inicial no eixo Y
            x2: 250,      // Ponto final no eixo X
            y2: 0,        // Ponto final no eixo Y
            lineWidth: 1, // Largura da linha
            lineColor: 'black', // Cor da linha
          },
        ],
      },

      {
        alignment: 'center',
        text: 'Consulte pela chave de acesso em\n',
        style: 'styleUrlConsulta'
      },

      {
        alignment: 'center',
        text: notaFiscalNFCe.urlConsultaNF,
        style: 'styleUrl'
      },

      {
        alignment: 'center',
        text: notaFiscalNFCe.chaveNfce,
        style: 'styleUrl'
      },

      //----------------------------------------------------------------------
      // Informações do NFC-e  
      //----------------------------------------------------------------------
      {
        alignment: 'center',
        text: '\n' + consumidorNFCe,
        style: 'styleInfoNFCe'
      },

      {
        style: 'styleInfoNFCe',
        columns: [
          {
            alignment: 'right',
            width: 110,
            text: 'NFC-e:  ' + notaFiscalNFCe.numeroNota
          },
          {
            alignment: 'right',
            width: 50,
            text: 'Serie:  ' + notaFiscalNFCe.serie,
          }
        ]  
      },

      {
        style: 'styleInfoNFCe',
        columns: [
          {
            alignment: 'center',
            width: 240,
            text: 'Protocolo Autorização:  ' + notaFiscalNFCe.nfProtocolo
          },
        ]  
      },

      {
        style: 'styleInfoNFCe',
        columns: [
          {
            alignment: 'center',
            width: 240,
            text: 'Data Autorização:  ' + notaFiscalNFCe.dataAutorizacao + '\n\n'
          },
        ]  
      },
      //----- Fim -----------------------
      
      //----------------------------------------------------------------------
      // QrCode - Converte em imagem 
      //----------------------------------------------------------------------
      {
        alignment: 'center',
        image: 'data:image/png;base64,' + notaFiscalNFCe.qrCode, // Substitua <BASE64_DATA> pelo conteúdo da imagem em Base64
        width: 95, // Define a largura da imagem
      },

      {
        alignment: 'center',
        text: '\nApp Chefe Faster disponível na play store\n',
        style: 'styleChefeFaster'
      },

      {
        alignment: 'center',
        text: 'www.chefefaster.com.br',
        style: 'styleChefeFaster'
      },

    ],
    styles: {
      header: {
        fontSize: 10,
        bold: true,
        margin: [-40, 2, -40, 0] //[left, top, right, bottom]
      },
      subheader: {
        fontSize: 8,
        bold: true,
        margin: [-40, 0, -40, 0] //[left, top, right, bottom]
      },
      styleProduto: {
        fontSize: 7,
        bold: true,
        margin: [-30, 0, -10, 0] //[left, top, right, bottom]
      },
      styleInfoNFCe: {
        fontSize: 8,
        bold: false,
        margin: [-30, 0, -10, 0] //[left, top, right, bottom]
      },

      styleValoresTotais: {
        fontSize: 8,
        bold: true,
        margin: [-30, 0, -10, 0] //[left, top, right, bottom]
      },

      styleFormaPagamento: {
        fontSize: 9,
        bold: true,
        margin: [-30, 0, -10, 0] //[left, top, right, bottom]
      },

      styleUrlConsulta: {
        fontSize: 12,
        bold: true,
        margin: [-40, 2, -40, 0] //[left, top, right, bottom]
      },

      styleUrl: {
        fontSize: 6,
        bold: false,
        margin: [-40, 0, -40, 0] //[left, top, right, bottom]
      },

      styleQrCode: {
        fontSize: 8,
        bold: false,
        margin: [-40, 0, -40, 0] //[left, top, right, bottom]
      },

      styleChefeFaster: {
        fontSize: 7,
        bold: false,
        margin: [-40, 0, -40, 0] //[left, top, right, bottom]
      },

      styleLinha: {
        fontSize: 7,
        bold: false,
        margin: [-40, 0, -40, 0] //[left, top, right, bottom]
      },

      descricao: {
        fontSize: 6,
        bold: true,
      },
      detalhes: {
        fontSize: 6,
      },

      quote: {
        italics: true
      },
      small: {
        fontSize: 8,
        margin: [-40, 0, -40, 0] //[left, top, right, bottom]
      }
    },   
  };


  


  if (tipoImpressao === 'V'){
    // Abre o PDF em uma nova aba
    pdfMake.createPdf(docDefinition).open();
  }else if (tipoImpressao === 'G'){
    pdfMake.createPdf(docDefinition).download();
  }
};
