import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertar } from "~/Util/alertar";
import ReactWizard from "~/components/Wizard/Wizard";
//import ReactTable from "react-table";
import ReactTable from "react-table-6";
import * as controllerPedidoVendas from "~/controller/Vendas/controllerPedidoVendas"
import * as controllerCaixa from "~/controller/Financeiro/controllerCaixa"
import * as controllerCliente from "~/controller/Cadastros/controllerCliente.js"

import classNames from "classnames";
import ArrowTooltip from "~/components/Tooltip/ArrowTooltip";
import {maskPercentual, maskMoeda, maskNumbers} from "~/Util/mascaras";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useHotkeys } from 'react-hotkeys-hook';



import useModal from '~/Util/Hooks/useModal.ts';
import useShallowEqualSelector from '~/Util/Hooks/useShallowEqualSelector.ts';
import ModalProdutos from '../Modals/modalProdutos.jsx'
import ModalClientes from '../Modals/modalClientes.jsx'
import ModalPedidos from '../Modals/modalPedidos.jsx'
import ModalItem from '../Modals/modalItem.jsx'
import ModalRemoverItem from "../Modals/modalRemoverItem.jsx";
import ModalFaturar from "../Modals/modalFaturar.jsx";
import ModalTrocar from "../Modals/modalTrocar.jsx";
import ModalReabrirExcluir from "../Modals/modalReabrirExcluir.jsx";
import ModalPgtoInformacao from "../Modals/modalPgtoInformacao.jsx";
import ModalPgtoParcial from "../Modals/modalPgtoParcial.jsx";
import ModalRetirada from "../Modals/modalRetirada.jsx";
import ModalEndereco from "../Modals/modalEnderecos.jsx";
import ModalVendedor from "../Modals/modalVendedor.jsx"
import ModalNFCe from "../Modals/modalNFCe.jsx"
import ModalImprimir from "~/pdf/modalImprimir.jsx";

import '~/views/Vendas/Pedido/Steps/styles.css'
import {
  Card,
  Col,
  Button,
  ButtonGroup,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Input,
  Row,
  TabContent,
  TabPane,
  Form,
  FormGroup,
  Modal,
  UncontrolledTooltip,
  Label,
  CardDeck,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";



const idStore = "PEDIDO"

const PedidoItem = () => {
  var tema = localStorage.getItem('TEMA')
  var pedidoItemEditar = []
  const notificacao = useSelector((state) => state.notificacao);
  const login = useSelector((state) => state.login);

  
  const { pedidoPedido, PEDIDOITENs, invalido, pedidoPedidoItem, pedidoPedidoFaturar, pedidoPagamentoParcial, pedidoEnderecoEntrega, pedidoFaturado, notaFiscalRetornoNFCe, abreModalImprimir } = useSelector((state) => state.PedidoItem);
  const { caixaUsuarioRetiradas } = useSelector((state) => state.Caixa);
  const { ENDERECOs } = useSelector((state) => state.Cliente);
  
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [dataTable, setDataTable] = useState([]);
  const [table, setTable] = useState(renderTable([]));
  const [tableVisible, setTableVisible] = useState(false);

  /*------------------------------------------------------------------------------ 
   * MODAIS
   *------------------------------------------------------------------------------*/
  const [paramTelaModal, setparamTelaModal] = useState({acaoTela: '', titulo: '' })
  const [paramTelaModalNFCe, setparamTelaModalNFCe] = useState({acaoTela: '', titulo: '' })
  const [pesquisa, setpesquisa] = useState({ produto: '' })
  const [pesquisaCliente, setpesquisaCliente] = useState({ cliente: '' })
  const [pedidoCampos, setpedidoCampos] = useState({ mesaComanda: '', idCliente: 0, observacao: '', idUsuarioVendedor: '', nomeUsuarioVendedor: '' })
  const [NFCeCampos, setNFCeCampos] = useState({ idEmpresa: '', idUsuario: '', idPedido: '', idNotaFiscal: '' })
  const { cadastroProduto } = useSelector((state) => state.PedidoPesqProduto);
  const { cadastroCliente } = useSelector((state) => state.PedidoPesqCliente);
  const [modalProdutos, toggleModalProdutos] = useModal(false)
  const [shouldClean, setshouldClean] = useState(false)
  const [modalClientes, toggleModalClientes] = useModal(false)
  const [modalPedidos, toggleModalPedidos] = useModal(false)
  const [modalItem, toggleModalItem] = useModal(false)
  const [modalRemoverItem, toggleModalRemoverItem] = useModal(false)
  const [modalFaturar, toggleModalFaturar] = useModal(false)
  const [modalTrocar, toggleModalTrocar] = useModal(false)
  const [modalReabrirExcluir, toggleModalReabrirExcluir] = useModal(false)
  const [modalPgtoInformacao, toggleModalPgtoInformacao] = useModal(false)
  const [modalPgtoParcial, toggleModalPgtoParcial] = useModal(false)
  const [modalRetirada, toggleModalRetirada] = useModal(false)
  const [modalEndereco, toggleModalEndereco] = useModal(false)
  const [modalVendedor, toggleModalVendedor] = useModal(false)
  const [modalNFCe, toggleModalNFCe] = useModal(false)
  const [modalImprimir, toggleModalImprimir] = useModal(false)
  

  //------ FIM MODAIS

  /*------------------------------------------------------------------------------ 
   * FOCUS dos Campos
   *------------------------------------------------------------------------------*/
  const inputRef = useRef()
  const focoMesaComanda = useRef(false)
  const focoProduto = useRef(false);
  const focoQuantidade = useRef(false);
  const focoValorProduto = useRef(false);

  function InicializarPedido(){
    dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: '' })
    let campo = "idCliente"
    let value = 0
    dispatch({ idStore, type: "PEDIDO", campo, value });
    campo = "nomeCliente"
    value = "***CONSUMIDOR***"
    dispatch({ idStore, type: "PEDIDO", campo, value });

    setpedidoCampos({ ...pedidoCampos, mesaComanda: '', idCliente: 0, observacao: '', idUsuarioVendedor: '', nomeUsuarioVendedor: '' })

    //CAMPOS PADRÃO DE INICIO DO PEDIDO
    dispatch({ idStore, type: "PEDIDO", campo: 'tipoVenda', value: 'B' });

    focoProduto.current = false;
    focoQuantidade.current = false;
    focoValorProduto.current = false;

    //Verifica se abre o poupup de vendedores
    if (login.usuario.vendedorPedido === 'S'){
      toggleModalVendedor(); 
    }

    document.getElementById("mesaComanda").focus();
  }   

  useEffect(() => {
    InicializarPedido();
  }, [])

/*   if (modalProdutos){
    document.getElementById("quantidade").focus();
  } */  



  /*------------------------------------------------------------------------------ 
   * FOCUS dos Campos
   *------------------------------------------------------------------------------*/
  if (focoMesaComanda.current){
    document.getElementById("mesaComanda").focus();
    focoMesaComanda.current = false;
  }   
  if (focoProduto.current){
    document.getElementById("idProduto").focus();
    focoProduto.current = false;
  }  
  
  if (focoQuantidade.current){
    document.getElementById("quantidade").focus();
    focoQuantidade.current = false;
  }
  if (focoValorProduto.current){
    document.getElementById("valorProduto").focus();
    focoValorProduto.current = false;
  }

  if (pedidoFaturado.faturado === 'S'){
    if (login.usuario.tipoPlano === 'PF'){
      toggleModalNFCe();
    }
    dispatch({ idStore, type: 'PEDIDO_FATURADO', campo: 'faturado', value: 'N' })
    InicializarPedido();  
  }

  if (abreModalImprimir.abrirModal === 'S'){
    let type = 'MODAL';
    dispatch({ idStore, type, campo: 'abrirModal', value: ''});
    toggleModalImprimir();
  }

  //---- Fim Focus


  // if (modalEndereco){
  //   if (pedidoEnderecoEntrega.idCliente !== ''){
  //     dispatch({ idStore, type:'PEDIDO', campo: 'nomeCliente', value: pedidoEnderecoEntrega.nomeEntrega });
  //     dispatch({ idStore, type:'PEDIDO', campo: 'idCliente', value: pedidoEnderecoEntrega.idCliente });
  //     dispatch({ idStore, type:'PEDIDO', campo: 'tipoVenda', value: 'E' });
  //   }
  // }
  
/*-----------------------------------------------------------------
 * Gride de Produtos
 *-----------------------------------------------------------------*/
  useEffect(() => {
    setTable(renderTable());
  }, [dataTable, loading]);

  function renderTable() {
    return dataTable.map((obj, key) => {
      return {
        checkboxItem: (
          <div style={{ textAlign: 'left', marginTop: '-30px', fontSize: '14px', fontWeight: 'normal', color: '#444444'}}>
            <FormGroup check>
              <Label check>
              {obj.impresso === 'S' ?
                <Input 
                  id="itemSelecionado" 
                  defaultChecked type="checkbox" 
                  onClick={() => {
                    handleCheckItem(obj);
                  }}
                ></Input>  
              :  
                <Input 
                  id="itemNaoSelecionado" 
                  type="checkbox" 
                  onClick={() => {
                    handleCheckItem(obj);
                  }}
                ></Input>              
              }  
                <span className="form-check-sign" />
              </Label>
            </FormGroup>
          </div>
        ),
        imagem: (
            <div className="photo">
              {obj.imagemProduto !== '' && obj.imagemProduto !== undefined ?
                <img
                alt="..."
                style={{ width: "50%", height: "50%" }}
                src={"data:image/png;base64," + obj.imagemProduto}
              />
              :
                <img
                alt="..."
                style={{ width: "50%", height: "50%" }}
                src={require("~/assets/img/SemImagem2.jpg")}
                />
              }
            </div>    
        ),
        acessorCodigoProduto: (
          <div style={{ textAlign: 'left', fontSize: '14px', fontWeight: 'normal', color: '#444444'  }}>
            {obj.codigoProduto}
          </div>
        ),
        acessorDescricaoProduto: (
          <div style={{ width: 'calc(100% + 50px)', textAlign: 'left', fontSize: '14px', fontWeight: 'normal', color: '#444444'  }}>
            {obj.produtoDescricao}
          </div>
        ),
        acessorQuantidade: (
          <div style={{ textAlign: 'center', fontSize: '14px', fontWeight: 'normal', color: '#444444' }}>
            {obj.quantidade}
          </div>
        ),
        acessorValorItem: (
          <div style={{ textAlign: 'right', fontSize: '14px', fontWeight: 'normal', color: '#444444' }}>
            {obj.valorItem}
          </div>
        ),
        acessorValorTotal: (
          <div style={{ textAlign: 'right', fontSize: '14px', fontWeight: 'normal', color: '#444444' }}>
            {obj.valorTotal}
          </div>
        ),
        ...dataTable[key],
        actions: (
          <div className="actions-center">
            <Button
              className="btn-link btn-icon"
              color="info"
              id="tooltipEditar"
              size="sm"
              title=""
              type="button"
              onClick={() => {
                handleEditarItem(obj);
              }}
            >
              <UncontrolledTooltip
                delay={0}
                target="tooltipEditar"
              >
                Editar
              </UncontrolledTooltip>                              
              <i className="tim-icons icon-pencil" />
            </Button>

            <Button
                className="btn-link btn-icon"
                // color={obj.statusUsuario === 'ATIVO' ? "danger" : "success" }
                color="danger"
                id="tooltipRemover"
                size="sm"
                title=""
                type="button"
                onClick={() => {
                  handleRemoverItem(obj);
                }}
              >              
                <UncontrolledTooltip
                  delay={0}
                  target="tooltipRemover"
                  >
                  Remover
                </UncontrolledTooltip>                              
                <i className="tim-icons  icon-simple-remove"/>
              </Button>

            
          </div>
        ),
      };
    });
  }

  //Monta Dados para Gride
  function tableData(data) {
    return data.map((prop, key) => {
      return {
        id: key,
        idPedidoItem: prop["idPedidoItem"],
        idPedido: prop["idPedido"],
        idEmpresa: prop["idEmpresa"],
        idProduto: prop["idProduto"],
        codigoProduto: prop["codigoProduto"],
        produtoDescricao: prop["produtoDescricao"],
        quantidade: prop["quantidade"],
        valorItem: maskMoeda(prop["valorItem"].toFixed(2)),
        valorTotal: maskMoeda(prop["valorTotal"].toFixed(2)),
        impresso: prop["impresso"],
        produzido: prop["produzido"],
        imagemProduto: prop["imagemProduto"],
        observacaoItem: prop["observacaoItem"],
        status: prop["status"],

      };
    });
  }


  //Se existir registro carrega e deixa a gride visivel
  useEffect(() => {
    setDataTable(tableData(PEDIDOITENs));
    if (PEDIDOITENs.length > 0) {
      setTableVisible(true);
    } else {
      setTableVisible(false);
    }
    // if (pedidoEnderecoEntrega.idCliente !== ''){
    //   pedidoPedido.tipoVenda = 'E';
    // }
  }, [PEDIDOITENs]);
  
//---Fim Gride Produtos


function handleCheckItem(obj) {

  var totalItens = PEDIDOITENs.length;
  for (var i = 0; i < totalItens; i++) {
    if (obj.idPedidoItem === PEDIDOITENs[i].idPedidoItem){
      if (obj.impresso === 'S'){
        PEDIDOITENs[i].impresso = 'N';
      }else {
        PEDIDOITENs[i].impresso = 'S';
      }    
    }
  }
}

/*-----------------------------------------------------------------
 * MODAIS
 *-----------------------------------------------------------------*/

function handleModalPedidos() {
  dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: '' })
  focoProduto.current = true;
  toggleModalPedidos();
}


function handleModalProdutos() {
  dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: '' })
  focoQuantidade.current = true;
  toggleModalProdutos();
}




async function handleEditarItem(obj) {
  dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: '' })
  setLoading(true);
  if (!loading) {
    if (pedidoPedido.pedidoAberto === 'N'){
      alertar(notificacao, 'br', "Pedido FECHADO.", 'warning', 'icon-alert-circle-exc', 3);
    }else{
      let type = 'PEDIDO_ITEM';
      dispatch({ idStore, type, campo: 'idPedidoItem', value: obj.idPedidoItem })
      dispatch({ idStore, type, campo: 'idPedido', value: obj.idPedido })
      dispatch({ idStore, type, campo: 'idEmpresa', value: obj.idEmpresa })
      dispatch({ idStore, type, campo: 'idProduto', value: obj.idProduto })
      dispatch({ idStore, type, campo: 'quantidade', value: obj.quantidade })
      dispatch({ idStore, type, campo: 'valorItem', value: obj.valorItem })
      dispatch({ idStore, type, campo: 'desconto', value: '0' })
      dispatch({ idStore, type, campo: 'valorTotal', value: obj.valorTotal})
      dispatch({ idStore, type, campo: 'observacaoItem', value: obj.observacaoItem })
      dispatch({ idStore, type, campo: 'produtoDescricao', value: obj.produtoDescricao })
      
      toggleModalItem();  
    }
  }
  setLoading(false);
}


async function handleRemoverItem(obj) {
  dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: '' })
  setLoading(true);
  if (!loading) {
    if (pedidoPedido.pedidoAberto === 'N'){
      alertar(notificacao, 'br', "Pedido FECHADO.", 'warning', 'icon-alert-circle-exc', 3);
    }else{
      let type = 'PEDIDO_ITEM';
      dispatch({ idStore, type, campo: 'idPedidoItem', value: obj.idPedidoItem })
      dispatch({ idStore, type, campo: 'idPedido', value: obj.idPedido })
      dispatch({ idStore, type, campo: 'idEmpresa', value: obj.idEmpresa })
      dispatch({ idStore, type, campo: 'idProduto', value: obj.idProduto })
      dispatch({ idStore, type, campo: 'quantidade', value: obj.quantidade })
      dispatch({ idStore, type, campo: 'valorItem', value: obj.valorItem })
      dispatch({ idStore, type, campo: 'desconto', value: '0' })
      dispatch({ idStore, type, campo: 'valorTotal', value: obj.valorTotal})
      dispatch({ idStore, type, campo: 'observacaoItem', value: obj.observacaoItem })
      dispatch({ idStore, type, campo: 'produtoDescricao', value: obj.produtoDescricao })
  
      toggleModalRemoverItem();
    }
  }
  setLoading(false);
}

async function handleFaturar() {
  dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: '' })
  setLoading(true);
  if (!loading) {
    if (pedidoPedido.mesaComanda === ''){
      alertar(notificacao, 'br', "Nenhum PEDIDO selecionado para Fechar.", 'warning', 'icon-alert-circle-exc', 2);
    }else if (PEDIDOITENs.length === 0){
      alertar(notificacao, 'br', "Nenhum Produto no Pedido para Fechar.", 'warning', 'icon-alert-circle-exc', 2);
    }else if (pedidoPedido.pedidoAberto === 'N'){
      alertar(notificacao, 'br', "Pedido FECHADO.", 'warning', 'icon-alert-circle-exc', 3);
    }else {
      //VERIFICA SE É PEDIDO DELIVERY
      if (pedidoPedido.tipoVenda === 'E'){
        if (pedidoEnderecoEntrega === ''){
          alertar(notificacao, 'br', "Pedido DELIVERY precisa do ENDEREÇO de ENTREGA.", 'warning', 'icon-alert-circle-exc', 3);
        }
      }

      dispatch({ idStore, type: 'CLEAN_PEDIDO_FATURAR' });
      let type = 'PEDIDO_FATURAR';

      dispatch({ idStore, type, campo: 'idPedido', value: pedidoPedido.idPedido })
      dispatch({ idStore, type, campo: 'idEmpresa', value: pedidoPedido.idEmpresa })
      dispatch({ idStore, type, campo: 'idCliente', value: pedidoPedido.idCliente })
      dispatch({ idStore, type, campo: 'idUsuario', value: pedidoPedido.idUsuario })
      dispatch({ idStore, type, campo: 'mesaComanda', value: pedidoPedido.mesaComanda })
      dispatch({ idStore, type, campo: 'mesaComandaVinculada', value: pedidoPedido.mesaComandaVinculada })
      dispatch({ idStore, type, campo: 'tipoVenda', value: pedidoPedido.tipoVenda })
      dispatch({ idStore, type, campo: 'tipoPedido', value: pedidoPedido.tipoPedido })
      dispatch({ idStore, type, campo: 'percentualTaxa', value: pedidoPedido.percentualTaxa })
      dispatch({ idStore, type, campo: 'valorDesconto', value: '0,00' })
      dispatch({ idStore, type, campo: 'valorTaxa', value: pedidoPedido.valorTaxa })
      dispatch({ idStore, type, campo: 'valorParcial', value: pedidoPedido.valorParcialTotal })
      dispatch({ idStore, type, campo: 'valorEntrega', value: pedidoPedido.valorEntrega })
      dispatch({ idStore, type, campo: 'valorSubTotal', value: pedidoPedido.valorSubTotal })
      dispatch({ idStore, type, campo: 'valorRestante', value: pedidoPedido.valorTotal })
      dispatch({ idStore, type, campo: 'valorTroco', value: '0,00' })
      dispatch({ idStore, type, campo: 'valorTotal', value: pedidoPedido.valorTotal })
      dispatch({ idStore, type, campo: 'valorPagoDigitado', value: '0,00' })
      dispatch({ idStore, type, campo: 'formaPagamento', value: '' })
      dispatch({ idStore, type, campo: 'enderecoEntrega', value: pedidoPedido.enderecoEntrega })

      setNFCeCampos({ idEmpresa: pedidoPedido.idEmpresa, 
                      idUsuario: pedidoPedido.idUsuario, 
                      idPedido: pedidoPedido.idPedido,
                      idNotaFiscal: ''})

      //Verifica se tem cupom para C = Cancelar ou I = Incluir                
      setparamTelaModalNFCe({acaoTela: 'I', titulo: 'Emissão de NFC-e' })                      
  
      focoMesaComanda.current = true
      toggleModalFaturar();  
    }
  }
  setLoading(false);
}


//--- Fim Modais

/*-----------------------------------------------------------------
 * BOTÕES
 *-----------------------------------------------------------------*/
  async function handleAdicionarItem(){
    dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: 'Adicionando Item...' })
    setLoading(true);
    if (!loading) {  
      if (cadastroProduto.idProduto !== '' && cadastroProduto.quantidade !== '' && cadastroProduto.valorProduto !== ''){
        if (pedidoPedido.idPedido === '' || pedidoPedido.mesaComanda === undefined || pedidoPedido.mesaComanda === ''){
          pedidoPedido.mesaComanda = pedidoCampos.mesaComanda;
          pedidoPedido.idCliente = cadastroCliente.idCliente;
          pedidoPedido.observacao = pedidoCampos.observacao;
          pedidoPedido.idUsuarioVendedor = pedidoCampos.idUsuarioVendedor;
          pedidoPedido.nomeUsuarioVendedor = pedidoCampos.nomeUsuarioVendedor;
        }
        //Verifica se Pedido estar aberto  
        if (pedidoPedido.pedidoAberto === 'N'){
          alertar(notificacao, 'br', "Pedido FECHADO.", 'warning', 'icon-alert-circle-exc', 3);
        }else{
          await controllerPedidoVendas.AddItem(notificacao, dispatch, login.usuario,  pedidoPedido, cadastroProduto, pedidoEnderecoEntrega, () => {
            focoProduto.current = true;
            //document.getElementById("idProduto").focus();  
          });  
        }
      }else{
        if (cadastroProduto.idProduto === ''){
          focoProduto.current = true;
          alertar(notificacao, 'br', "Informe um PRODUTO.", 'info', 'icon-alert-circle-exc', 2);
        }else if (cadastroProduto.quantidade === ''){
          focoQuantidade.current = true;
          alertar(notificacao, 'br', "Informe a QUANTIDADE.", 'info', 'icon-alert-circle-exc', 2);
        }else if (cadastroProduto.valorProduto === ''){
          focoValorProduto.current = true;
          alertar(notificacao, 'br', "Informe o VALOR do PRODUTO.", 'info', 'icon-alert-circle-exc', 2);
        }        
      }
    }
    setLoading(false);
  }

/*-----------------------------------------------------------------
 * MENUs
 *-----------------------------------------------------------------*/
function menuNovo(){
  //Limpa Objetos
  let idStore = 'PEDIDO'
  dispatch({ idStore, type: "CLEAN_INVALIDO" });
  dispatch({ idStore, type: "CLEAN_SEARCH" });
  dispatch({ idStore, type: "CLEAN_PEDIDO" });
  dispatch({ idStore, type: "CLEAN_PEDIDO_ITEM" });
  dispatch({ idStore, type: "CLEAN_PEDIDO_FATURAR" });
  dispatch({ idStore, type: "CLEAN_PEDIDO_PAGAMENTO_PARCIAL" });
  dispatch({ idStore, type: "CLEAN_INVALIDO_PAGAMENTO_PARCIAL" });
  dispatch({ idStore, type: "CLEAN_PESQUISA_PEDIDO_ENDERECO" });
  dispatch({ idStore, type: "CLEAN_PEDIDO_ENDERECO" });
  
  dispatch({ idStore, type: 'PEDIDOITENs', value: [] })  
  dispatch({ idStore, type: 'PEDIDOs', value: [] })  
  dispatch({ idStore, type: 'PEDIDO_ENDERECOs', value: [] })  

  idStore = 'PEDIDO_PESQ_CLIENTE'
  dispatch({ idStore, type: "CLEAN_CLIENTE" });  

  idStore = 'PEDIDO_PESQ_PRODUTO'
  dispatch({ idStore, type: 'CLEAN_PRODUTO' })  
  dispatch({ idStore, type: 'CLEAN_SEARCH' })  
  dispatch({ idStore, type: 'CLEAN_INVALIDO' })  
  dispatch({ idStore, type: 'PRODUTOs', value: [] })  

  InicializarPedido();  
      
}

//Envia a Conta para Impressão no chefeDesktop
async function menuEnviarContaImpressao(){
  if (pedidoPedido.idPedido !== ''){
    dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: 'Enviando para Impressão...' })
    setLoading(true);
    if (!loading) {  
      await controllerPedidoVendas.EnviarContaImpressao(notificacao, dispatch, pedidoPedido, () => {
        document.getElementById("idProduto").focus();  
      });
    }    
    //Verifica se Pedido estar aberto  
    // if (pedidoPedido.pedidoAberto === 'N'){
    //   alertar(notificacao, 'br', "Pedido FECHADO.", 'info', 'icon-alert-circle-exc', 3);
    // }else{
    //   setLoading(true);
    //   if (!loading) {  
    //     await controllerPedidoVendas.EnviarContaImpressao(notificacao, dispatch, pedidoPedido, () => {
    //       document.getElementById("idProduto").focus();  
    //     });
    //   }    
    // }
  }else{
    alertar(notificacao, 'br', "Informe um PEDIDO.", 'info', 'icon-alert-circle-exc', 2);
  }  
  setLoading(false);
}

//Envia Itens para Impressão no chefeDesktop
async function menuEnviarItensProducao(){
  if (pedidoPedido.idPedido !== ''){
    //Verifica se Pedido estar aberto  
    if (pedidoPedido.pedidoAberto === 'N'){
      alertar(notificacao, 'br', "Pedido FECHADO.", 'info', 'icon-alert-circle-exc', 3);
    }else{
      dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: 'Enviando para Produção...' })
      setLoading(true);
      if (!loading) {  
         await controllerPedidoVendas.EnviarItensProducao(notificacao, dispatch, PEDIDOITENs, () => {
           document.getElementById("idProduto").focus();  
         });
       }    
     }    
  }else{
      alertar(notificacao, 'br', "Informe um PEDIDO.", 'info', 'icon-alert-circle-exc', 2);
  }  
  setLoading(false);
}
  

//Abre modal para imperssão de NFCe
async function menuNfce(){
  if (pedidoPedido.idPedido !== ''){
    dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: 'Carregando...' })
    setLoading(true);
    if (!loading) {  
      await controllerPedidoVendas.GetDadosNFCe(notificacao, dispatch, login.usuario.empresa, pedidoPedido.idPedido, pedidoPedido.idNotaFiscal, () => {
        toggleModalImprimir(); 
      });
    }    
  }else{
    alertar(notificacao, 'br', "Informe um PEDIDO.", 'info', 'icon-alert-circle-exc', 2);
  }  
  setLoading(false);
}


//Trocar Mesa ou Comanda
async function menuTrocar() {
  dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: '' })
  setLoading(true);
  if (!loading) {
    if (pedidoPedido.mesaComanda === ''){
      alertar(notificacao, 'br', "Nenhum PEDIDO selecionado para Trocar.", 'info', 'icon-alert-circle-exc', 2);
    }else if (PEDIDOITENs.length === 0){
      alertar(notificacao, 'br', "Nenhum Produto no Pedido para Trocar.", 'info', 'icon-alert-circle-exc', 2);
    }else if (pedidoPedido.pedidoAberto === 'N'){
      alertar(notificacao, 'br', "Pedido FECHADO.", 'info', 'icon-alert-circle-exc', 2);
    }else{  
      toggleModalTrocar();  
    }
  }
  setLoading(false);
}

//Reabre o Pedido
async function menuReabrirPedido() {
  dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: '' })
  setLoading(true);
  if (!loading) {
    if (pedidoPedido.mesaComanda === ''){
      alertar(notificacao, 'br', "Nenhum PEDIDO selecionado.", 'warning', 'icon-alert-circle-exc', 2);
    }else if (PEDIDOITENs.length === 0){
      alertar(notificacao, 'br', "Nenhum Produto no Pedido.", 'warning', 'icon-alert-circle-exc', 2);
    }else{  
      if (pedidoPedido.pedidoAberto === 'S'){
        alertar(notificacao, 'br', "Pedido Já Está ABERTO.", 'warning', 'icon-alert-circle-exc', 3);
      }else{
        setparamTelaModal({ ...paramTelaModal, acaoTela: 'R', titulo: 'Reabrir Pedido' })
        toggleModalReabrirExcluir(); 
      }
    }
  }
  setLoading(false);
}

//Excluir Pedido
async function menuExcluirPedido() {
  dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: '' })
  setLoading(true);
  if (!loading) {
    if (pedidoPedido.mesaComanda === ''){
      alertar(notificacao, 'br', "Nenhum PEDIDO selecionado.", 'warning', 'icon-alert-circle-exc', 2);
    }else if (PEDIDOITENs.length === 0){
      alertar(notificacao, 'br', "Nenhum Produto no Pedido.", 'warning', 'icon-alert-circle-exc', 2);
    }else{  
      if (pedidoPedido.pedidoAberto === 'N'){
        alertar(notificacao, 'br', "Você precisa REABRIR o Pedido Primeiro.", 'warning', 'icon-alert-circle-exc', 3);
      }else{
        setparamTelaModal({ ...paramTelaModal, acaoTela: 'E', titulo: 'Excluir Pedido' })
        toggleModalReabrirExcluir(); 
      }
    }
  }
  setLoading(false);
}

//Pagamento Parcial
async function menuPgtoParcial() {
  dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: '' })
  setLoading(true);
  if (!loading) {
    if (pedidoPedido.mesaComanda === ''){
      alertar(notificacao, 'br', "Nenhum PEDIDO selecionado para Fechar.", 'warning', 'icon-alert-circle-exc', 2);
    }else if (PEDIDOITENs.length === 0){
      alertar(notificacao, 'br', "Nenhum Produto no Pedido para Fechar.", 'warning', 'icon-alert-circle-exc', 2);
    }else if (pedidoPedido.idPedido === ''){
      alertar(notificacao, 'br', "Pedido NÃO LANÇADO.", 'warning', 'icon-alert-circle-exc', 3);
    }else if (pedidoPedido.pedidoAberto === 'N'){
        alertar(notificacao, 'br', "Pedido FECHADO.", 'warning', 'icon-alert-circle-exc', 3);
    }else {

      dispatch({ idStore, type: 'CLEAN_PEDIDO_PAGAMENTO_PARCIAL' });
      let type = 'PEDIDO_PAGAMENTO_PARCIAL';

      dispatch({ idStore, type, campo: 'idPedido', value: pedidoPedido.idPedido })
      dispatch({ idStore, type, campo: 'idUsuario', value: pedidoPedido.idUsuario })

      toggleModalPgtoParcial();  

    }
  }
  setLoading(false);
}

//Retirada - Sangria de Caixa
async function menuRetirada() {
  dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: '' })
  setLoading(true);
  if (!loading) {
      await controllerCaixa.GetRetiradasCaixaUsuario(notificacao, dispatch, login.usuario.empresa, login.usuario.idUsuarioLogin);

      toggleModalRetirada();
  }
  setLoading(false);
}


//Informações de Pagamento
async function menuPgtoInformacao() {
  dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: '' })
  setLoading(true);
  if (!loading) {
    if (pedidoPedido.mesaComanda === ''){
      alertar(notificacao, 'br', "Nenhum PEDIDO selecionado.", 'warning', 'icon-alert-circle-exc', 2);
    }else if (PEDIDOITENs.length === 0){
      alertar(notificacao, 'br', "Nenhum Produto no Pedido.", 'warning', 'icon-alert-circle-exc', 2);
    }else if (pedidoPedido.idPedido === ''){
      alertar(notificacao, 'br', "Pedido NÃO Gravado.", 'warning', 'icon-alert-circle-exc', 3);
    }else {

    await controllerPedidoVendas.GetPagamentoInformacao(notificacao, dispatch, pedidoPedido.idEmpresa, pedidoPedido.idPedido, () => {
        //document.getElementById("idProduto").focus();  
    });

    toggleModalPgtoInformacao();  

    }
  }
  setLoading(false);
}


// ---- Fim Menus 

//Endreços - Delivery
async function handleEnderecos() {
  setLoading(true);
  if (!loading) {
    await controllerCliente.GetClienteEnderecoCelular(notificacao, dispatch, pedidoPedido.mesaComanda, () => {
      toggleModalEndereco();  
    });
  }
  setLoading(false);
}


/*-----------------------------------------------------------------
 * ALTERAÇÃO DE CAMPOS
 *-----------------------------------------------------------------*/
  function handleOnChange(value, campo) {
    switch (campo) {
      case "mesaComanda":
        setpedidoCampos({ ...pedidoCampos, mesaComanda: value })
        break;
      case "observacao":
        setpedidoCampos({ ...pedidoCampos, observacao: value })                    
        break;
      default:
        break;
    } 
    dispatch({ idStore, type: "PEDIDO", campo, value });
  }

  function handleOnChangeProduto(value, campo) {
    setpesquisa({ ...pesquisa, produto: value })

    let idStore = 'PEDIDO_PESQ_PRODUTO';
    dispatch({ idStore, type: "PRODUTO", campo, value });
  }

  function handleOnChangeCliente(value, campo) {
    setpesquisaCliente({ ...pesquisaCliente, cliente: value })
    dispatch({ idStore, type: "PEDIDO", campo, value });
  }

  // FIM ALTERAÇÃO DE CAMPOS


  /*-----------------------------------------------------------------
  * PESQUISAS
  *-----------------------------------------------------------------*/
  async function handleBuscarProduto(){
    dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: 'Pesquisando...' })

    setLoading(true);
    if (!loading) {
      setpesquisa({ ...pesquisa, produto: cadastroProduto.idProduto })

      //Limpa Objetos de Pesquisa
      let idStore = 'PEDIDO_PESQ_PRODUTO'
      dispatch({ idStore, type: 'CLEAN_PRODUTO' })  
      dispatch({ idStore, type: 'PRODUTOs', value: [] })  

      if (!isNaN(parseFloat(pesquisa.produto)) && isFinite(pesquisa.produto)) {
        await controllerPedidoVendas.GetProduto(notificacao, dispatch, login.usuario.empresa, pesquisa.produto, () => {
            document.getElementById("quantidade").focus();  
        });
      }else{
        document.getElementById("btnPesqProduto").click();    
      }
    }
    setLoading(false);
  }

  async function handleBuscarMesa(){
    dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: 'Pesquisando...' })
    setLoading(true);
    if (!loading) {
      //setpesquisa({ ...pesquisa, produto: cadastroProduto.idProduto })

      //Verifica se eh um numero de celular/telefone e se tem DDD
      if (pedidoPedido.mesaComanda.length >= 8){
        if (pedidoPedido.mesaComanda.length === 8){
          alertar(notificacao, 'br', "Informe o Número com o DDD.", 'warning', 'icon-alert-circle-exc', 3);
          setLoading(false);
          return true;
        }else if (pedidoPedido.mesaComanda.length === 9){
          alertar(notificacao, 'br', "Informe o Número com o DDD.", 'warning', 'icon-alert-circle-exc', 3);
          setLoading(false);
          return true;
        }    
      }

      //Limpa Objetos de Pesquisa
      dispatch({ idStore, type: 'CLEAN_PEDIDO' })  
      dispatch({ idStore, type: 'PEDIDOITENs', value: [] })  
      var mesaComandaDelivery = 0;
      mesaComandaDelivery = pedidoPedido.mesaComanda;

      let campo = "mesaComanda"
      dispatch({ idStore, type: "PEDIDO", campo, mesaComandaDelivery });
      campo = "nomeCliente"
      let value = "***CONSUMIDOR***"
      dispatch({ idStore, type: "PEDIDO", campo, value });

      //Regra para vendedor
      if (login.usuario.vendedorPedido === 'S'){
        setpedidoCampos({ ...pedidoCampos, idUsuarioVendedor: pedidoPedido.idUsuarioVendedor, nomeUsuarioVendedor: pedidoPedido.nomeUsuarioVendedor });
      }else{
        setpedidoCampos({ ...pedidoCampos, idUsuarioVendedor: login.usuario.idUsuarioLogin, nomeUsuarioVendedor: login.usuario.nome });
      }

      if (!isNaN(parseFloat(pedidoPedido.mesaComanda)) && isFinite(mesaComandaDelivery)) {
        await controllerPedidoVendas.GetMesaComanda(notificacao, dispatch, login.usuario.empresa, pedidoPedido.mesaComanda, '', () => {
            //Verifica se eh um numero de celular
            if (pedidoPedido.mesaComanda.length >= 10){
              if (pedidoPedido.idPedido === ''){
                focoProduto.current = true;
                toggleModalEndereco();
              }
              
            }

            document.getElementById("idProduto").focus();  
        });
      }else{
       //     
      }
    }
    setLoading(false);
  }


  async function handleKeyPress(event, type) {
    if (event.key === "Enter") {
      switch (type) {
        case "mesaComanda":
          event.preventDefault(); //Não fazer o reload da pagina toda
          if (pedidoPedido.mesaComanda !== ""){
            //inputNumProdRef.current.focus()
            handleBuscarMesa();
            document.getElementById("idProduto").focus();  
          }
          break;
        case "idProduto":
          event.preventDefault(); //Não fazer o reload da pagina toda
          if (cadastroProduto.idProduto !== ''){
            handleBuscarProduto();    
          }else{
            document.getElementById("btnPesqProduto").click();  
          }
          break;
        case "quantidade":
          event.preventDefault(); //Não fazer o reload da pagina toda
          if (cadastroProduto.quantidade !== ""){
            handleAdicionarItem();
            document.getElementById("valorProduto").focus();  
          }
          break;
        case "valorProduto":
          event.preventDefault(); //Não fazer o reload da pagina toda
          if (cadastroProduto.valorProduto !== ""){
            handleAdicionarItem();
            document.getElementById("idProduto").focus();  
          }
          break;  
        case "observacaoProduto":
          event.preventDefault(); //Não fazer o reload da pagina toda
          if (cadastroProduto.idProduto !== "" && cadastroProduto.quantidade !== "" && cadastroProduto.valorProduto !== ""){
            handleAdicionarItem();
            document.getElementById("idProduto").focus();  
          }
          break;
    
        default:
          break;
      }
    }
  }

  useHotkeys('shift+N', () => {
    menuNovo();
  }, [])



  return (
    <>
      <div id="content-pedidoVenda" className="content"  style={{marginTop: "-75px", height: '100%' }}>
        {/* <div className="container__products">   */}

          <Col className="mr-auto ml-auto" md="14">
          
            <Row style={{ marginTop: "0px" }}>
              <Col Col className="mr-auto ml-auto" lg="3" md="6">
                <Card className="padding-padrao-row" style={{backgroundColor: 'silver' }}>                  
                  <TabContent
                    className="tab-space"
                    activeTab="mesa"
                    style={{ paddingBottom: "0px" }}
                  >
                    <TabPane tabId="mesa" style={{ marginTop: "-15px"}}>
                      <Row className="padding-padrao-row">
                        <Col className="padding-padrao-col" lg="5" style={{ marginTop: '0px' }}>
                          <Button
                              style={{marginLeft:'-10px', width: '109%', marginTop: '0px', textTransform:"none" }}
                              color="linkedin"
                              title='Pesquisar Pedidos'
                              className="btn-simple"
                              onClick={() => handleModalPedidos()}
                          >
                              Número <Icon icon="search" />
                          </Button>
                        </Col>

                        <Col className="padding-padrao-col" lg="7" md="3">
                          <FormGroup className={invalido.mesaComanda ? "has-danger" : ''}>
                            <Input
                              //innerRef={inputNumProdRef}
                              tabIndex={1}
                              style={{ fontSize: '18px', color: 'black', textAlign: "center", backgroundColor: '#f8f7d9' }}
                              id="mesaComanda"
                              type="text"
                              color="info"
                              autoComplete="off"
                              value={pedidoPedido.mesaComanda !== '' ? pedidoPedido.mesaComanda : pedidoCampos.mesaComanda }
                              onChange={(e) =>
                                handleOnChange(maskNumbers(e.target.value), e.target.id)
                              }
                              onFocus={e => e.target.select()}
                              onKeyPress={(e) => {
                                if (e.target.value !== ''){
                                  handleKeyPress(e, e.target.id);
                                  if (e.key === 'Enter') e.target.select()  
                                }
                              }}
                              //onKeyPress={(e) => handleKeyPress(e)}
                              //autoFocus={true}
                              maxLength="12"
                            />
                          </FormGroup>  
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </Card>
              </Col>
              <Col md='9'>
                <Card className="padding-padrao-row" style={{backgroundColor: 'silver' }}>                  
                  <TabContent
                    className="tab-space"
                    activeTab="cliente"
                    style={{ paddingBottom: "0px" }}
                  >
                    <TabPane tabId="cliente" style={{ marginTop: "-15px"}}>
                      <Row className="padding-padrao-row"> 
                        <Col className="padding-padrao-col" lg="1">
                          <Button
                              style={{marginLeft:'-15px', marginTop: '0px', width:'118%', textTransform:"none" }}
                              color="linkedin"
                              title='Pesquisar Cliente'
                              className="btn-simple"
                              onClick={() => toggleModalClientes()}
                              //onClick={() => handleBuscarCliente()}
                          >
                              Cliente <Icon icon="search" />
                          </Button>
                        </Col>
                        <Col className="padding-padrao-col" lg="5" md="4" >                          
                          <Input
                            tabIndex={2}
                            style={{textTransform:"uppercase" , fontSize: '18px', color: 'black', backgroundColor: '#f8f7d9' }}
                            id="nomeCliente"
                            type="text"
                            autoComplete="off"
                            value={cadastroCliente.nomeCliente !== ''? cadastroCliente.nomeCliente: pedidoPedido.nomeCliente}
                            onChange={(e) =>
                              handleOnChangeCliente(e.target.value, e.target.id)
                            }
                            onKeyPress={(e) => handleKeyPress(e)}
                             maxLength="30"
                          />
                        </Col>             
                        <Col className="padding-padrao-col" lg="1" md="1" style={{ marginTop: '0px' }}>
                          <Input
                            style={{ fontSize: '13px', textAlign: 'left'}}
                            id="labelObs"
                            type="text"
                            autoComplete="off"
                            disabled
                            maxLength="30"
                            value="Observ."
                          /> 

                          <img                            
                              alt="..."
                              style={pedidoPedido.pedidoAberto === 'N'? { width: "250px", height: "250px", position: 'fixed', top: '10px', zIndex: '1000'} : { width: "100px", height: "100px", position: 'fixed', top: '30px', zIndex: '1000', display: 'none'}} 
                              src={require("~/assets/img/imagens/contaPaga.png")}
                          />


                        </Col>                        

                        <Col className="padding-padrao-col" lg="5" md="3">                          
                          <Input
                            tabIndex={3}
                            style={{textTransform:"uppercase" , fontSize: '18px', color: 'black', backgroundColor: '#f8f7d9' }}
                            id="observacao"
                            type="text"
                            autoComplete="off"
                            value={pedidoPedido.observacao !== '' ? pedidoPedido.observacao : pedidoCampos.observacao}
                            onChange={(e) =>
                              handleOnChange(e.target.value, e.target.id)
                            }
                            onKeyPress={(e) => handleKeyPress(e)}
                             maxLength="50"
                          />
                        </Col> 
                      </Row>
                      <Row style={{ height: '5px' }}>
                      </Row>

                    </TabPane>
                  </TabContent>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: '-25px'}}>
            {/* <Row style={{ marginTop: '-25px', height:"100%" }}> */}
              <Col className="mr-auto ml-auto" lg="3" md="5">
                {/* <Card className="padding-padrao-row" style={{height:"calc(100% + 50px)", paddingTop: 0, width: '100%', backgroundColor: "silver"}}>                   */}
                {/* <Card className="padding-padrao-row" style={{height:"100%", paddingTop: 0, width: '100%', backgroundColor: "silver"}}> */}
                <Card className="padding-padrao-row" style={{ paddingTop: 0, width: '100%', backgroundColor: "silver"}}>                                       
                  <TabContent
                    className="tab-space"
                    activeTab="produto"
                    style={{ paddingBottom: "0px" }}
                  >
                    <TabPane tabId="produto" style={{ marginTop: "-15px"}}>
                      <Row className="padding-padrao-row">
                        {/* <Col className="padding-padrao-col" lg="12" md="9"> */}
                        {/* <Col className="padding-padrao-col" style={{ width: "100%", height: "100%" }}> */}
                        <Col className="padding-padrao-col" lg="12" md="8"> 
                          <fieldset className="fieldset-style">
                            {cadastroProduto.imagemProduto !== '' && cadastroProduto.imagemProduto !== undefined ?
                              <img
                              alt="..."
                              style={{ width: "100%", height: "100%" }}
                              src={"data:image/png;base64," + cadastroProduto.imagemProduto}
                              />
                              :
                                <img
                                alt="..."
                                style={{ width: "100%", height: "100%"}}
                                src={require("~/assets/img/SemImagem2.jpg")}
                                />
                            }
                          </fieldset>    
                        </Col>  
                      </Row>
                      <Row className="padding-padrao-row">
                        <Col className="padding-padrao-col" lg="12" md="8"> 
                          <fieldset className="fieldset-style" style={{height: '40px', textAlign: 'center'}}>
                            <i style={{maxWidth: '30', fontWeight: 'bold', fontStyle: 'normal' , color: "#084183" }}>{cadastroProduto.descricaoProduto}</i>    
                          </fieldset>    
                        </Col>  
                      </Row>

                      <Form className="form-horizontal">
                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5">
                            <Button
                                id="btnPesqProduto"
                                style={{marginLeft:'0px', width: '100%', marginTop:'0px', textAlign: 'left', textTransform:"none" }}
                                color="linkedin"
                                title='Pesquisar Produto'
                                className="btn-simple"
                                onClick={() => handleModalProdutos()}
                            >
                              Produto <Icon icon="search" />   
                            </Button>
                          </Col>

                          <Col className="padding-padrao-col" lg="7" md="1" style={{ marginLeft: '0px' }}>
                            
                            <Input
                              tabIndex={4}
                              style={{textAlign: 'center', textTransform:"uppercase" , fontSize: '18px', color: 'black', backgroundColor: '#f8f7d9'}}
                              id="idProduto"
                              type="text"
                              autoComplete="off"
                              value={cadastroProduto.idProduto}
                              onChange={(e) =>
                                handleOnChangeProduto(e.target.value, e.target.id)
                              }
                              onFocus={e => e.target.select()}
                              //ref={inputRef}
                              //onFocus={handleFocus}
                              //onBlur={handleBlur}
                              onKeyPress={(e) => {
                                if (e.target.value !== ''){
                                  handleKeyPress(e, e.target.id);
                                  if (e.key === 'Enter') e.target.select()  
                                }
                              }}
                              maxLength="30"
                            />
                          </Col>             
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '-5px' }}>
                            <Input
                              style={{ fontSize: '13px', textAlign: 'left' }}
                              id="labelQuantidade"
                              type="text"
                              autoComplete="off"
                              disabled
                              value="Quantidade"
                            />
                          </Col>
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '-5px' }}>
                            <Input
                              tabIndex={5}
                              style={{textAlign: 'center', textTransform:"uppercase" , fontSize: '18px', color: 'black', backgroundColor: '#f8f7d9'}}
                              id="quantidade"
                              type="text"
                              autoComplete="off"
                              value={cadastroProduto.quantidade}
                              onChange={(e) =>
                                handleOnChangeProduto(e.target.value, e.target.id)
                              }
                              onFocus={e => e.target.select()}
                              //ref={inputRef}
                              //onFocus={handleFocus}
                              //onBlur={handleBlur}
                              onKeyPress={(e) => {
                                if (e.target.value !== ''){
                                  handleKeyPress(e, e.target.id);
                                  if (e.key === 'Enter') e.target.select()  
                                }
                              }}
                              maxLength="4"
                            />
                          </Col>        
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '-2px' }}>
                            <Input
                              id="labelValor"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '13px', textAlign: 'left' }}
                              value="Valor R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '-2px' }}>
                            <Input
                              tabIndex={6}
                              style={{textAlign: 'right', textTransform:"uppercase" , fontSize: '18px', color: 'black', backgroundColor: '#f8f7d9' }}
                              borderTopRightRadius="0"
                              id="valorProduto"
                              type="text"
                              autoComplete="off"
                              value={cadastroProduto.valorProduto}
                              onChange={(e) =>
                                handleOnChangeProduto(maskMoeda(e.target.value), e.target.id)
                              }
                              onFocus={e => e.target.select()}
                              onKeyPress={(e) => {
                                if (e.target.value !== ''){
                                  handleKeyPress(e, e.target.id);
                                  if (e.key === 'Enter') e.target.select()  
                                }
                              }}
                              maxLength="15"
                            />
                          </Col>
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '-2px' }}>
                              <Input
                              style={{ fontSize: '13px', textAlign: 'left'}}
                              id="labelObsProduto"
                              type="text"
                              autoComplete="off"
                              disabled
                              value="Observação"
                            /> 
                          </Col>

                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '-2px' }}>
                            <Input
                              tabIndex={7}
                              style={{textAlign: 'left', textTransform:"uppercase" , fontSize: '18px', color: 'black', backgroundColor: '#f8f7d9' }}
                              id="observacaoProduto"
                              type="text"
                              autoComplete="off"
                              value={cadastroProduto.observacaoProduto}
                              onChange={(e) =>
                                handleOnChangeProduto(e.target.value, e.target.id)
                              }
                              onFocus={e => e.target.select()}
                              onKeyPress={(e) => {
                                if (e.target.value !== ''){
                                  handleKeyPress(e, e.target.id);
                                  if (e.key === 'Enter') e.target.select()  
                                }
                              }}
                              maxLength="20"
                            />
                          </Col>             
                        </Row>
                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="12">
                            <Button
                                style={{width: "100%", marginLeft:'0px', marginTop:'0px', textAlign: 'center', textTransform:"none" }}
                                //color="info"
                                title='Adicionar Produto'
                                className="default"
                                
                                onClick={() => handleAdicionarItem()}
                            >
                              Adicionar Item <Icon icon="save" style={{marginLeft:'30px'}}/>   
                            </Button>
                          </Col>

                        </Row>
                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="12">
                            <Button 
                                className='btn btn-greenDark'
                                style={{width: "100%", marginLeft:'0px', marginTop:'-5px', textAlign: 'center', textTransform:"none" }}
                                title='Faturar Pedido'
                                onClick={() => handleFaturar()}
                            >
                              Fechar Pedido <i className="tim-icons icon-money-coins" style={{marginLeft:'30px'}} />
                            </Button>
                          </Col>

                        </Row>        

                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="12">
                            <div className="timeline-footer">
                            <UncontrolledDropdown direction="up" style={{width: "100%", marginTop:'-5px' }}>
                              <DropdownToggle
                                caret
                                className="btn-block"
                                color="info"
                                data-toggle="dropdown"
                                type="button"                                
                              >
                                  Outras Opções
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem header>Menu</DropdownItem>
                                <DropdownItem
                                  href="#ern"
                                  onClick={() => menuNovo()}
                                >
                                  <i className="tim-icons icon-simple-add" style={{marginLeft:'10px'}} />Novo Pedido
                                </DropdownItem>
                                <DropdownItem
                                  href="#ern"
                                  onClick={() => menuEnviarContaImpressao()}
                                >
                                  <i className="tim-icons icon-notes" style={{marginLeft:'10px'}} />Imprimir Conta
                                </DropdownItem>
                                <DropdownItem
                                  href="#ern"
                                  onClick={() => menuEnviarItensProducao()}
                                >
                                  <i className="tim-icons icon-paper" style={{marginLeft:'10px'}} />Enviar Produção
                                </DropdownItem>
                                <DropdownItem
                                  href="#ern"
                                  onClick={() => menuNfce()}
                                >
                                  <i className="tim-icons icon-single-copy-04" style={{marginLeft:'10px'}} />Cupom Fiscal (NFC-e)
                                </DropdownItem>                                
                                <DropdownItem
                                  href="#ern"
                                  onClick={() => menuTrocar()}
                                >
                                  <i className="tim-icons icon-refresh-02" style={{marginLeft:'10px'}} />Troca de Mesa
                                </DropdownItem>
                                <DropdownItem
                                  href="#ern"
                                  onClick={() => menuReabrirPedido()}
                                >
                                  <i className="tim-icons icon-basket-simple" style={{marginLeft:'10px'}} />Reabrir Pedido
                                </DropdownItem>
                                <DropdownItem
                                  href="#ern"
                                  onClick={() => menuExcluirPedido()}
                                >
                                  <i className="tim-icons icon-simple-remove" style={{marginLeft:'10px'}} />Excluir Pedido
                                </DropdownItem>
                                <DropdownItem
                                  href="#ern"
                                  onClick={() => menuPgtoParcial()}
                                >
                                  <i className="tim-icons icon-wallet-43" style={{marginLeft:'10px'}} />Pagamento Parcial
                                </DropdownItem>                                
                                <DropdownItem
                                  href="#ern"
                                  onClick={() => menuPgtoInformacao()}
                                >
                                  <i className="tim-icons icon-money-coins" style={{marginLeft:'10px'}} />Informações de Pagamento
                                </DropdownItem>
                                <DropdownItem
                                  href="#ern"
                                  onClick={() => menuRetirada()}
                                >
                                  <i className="tim-icons icon-coins" style={{marginLeft:'10px'}} />Retirada de Caixa
                                </DropdownItem>
                                <DropdownItem
                                  href="#ern"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="tim-icons icon-button-power" style={{marginLeft:'10px'}} />Sair
                                </DropdownItem>

                              </DropdownMenu>
                            </UncontrolledDropdown>

                            </div>                        
                          </Col>                 

                        </Row>

                      </Form>

                      <br></br>
                    </TabPane>
                  </TabContent>
                </Card>

              </Col>
              <Col className="mr-auto ml-auto" md="9">
                {/* <Card className="card-stats" style={{height:"calc(100% + 50px)", paddingTop: 0, width: '100%', backgroundColor: "#b9b9b9"}}> */}
                <Card className="card-stats" style={{height: '95%', paddingTop: 0, width: '100%', backgroundColor: "#b9b9b9"}}>
                  <CardBody style={{ height: '100%', width: '100%'}}>
                    {/* <Row className="padding-padrao-row" style={{ paddingBottom: "0px", width: '1035px', height: '100%', position: 'relative'}}> */}
                    <Row className="padding-padrao-row" style={{ paddingBottom: "0px", minWidth: '1035px', height: '100%', position: 'relative'}}>
                      <Col className="padding-padrao-col" style={{height: '100%', position: 'relative'}}>
                        {/* <Card className="padding-padrao-row" style={{backgroundColor: "silver", textAlign: 'right'}}>                   */}
                          <TabContent
                            className="tab-space"
                            activeTab="pedido"
                            style={{ paddingBottom: "0px", width: '100%', height: '100%', position: 'relative'}}
                          >
                            <TabPane tabId="pedido" style={{ marginTop: "-15px", marginLeft: '0px', height: '100%', position: 'relative'}}>
                              {/* <Row className="padding-padrao-row" style={{ height: '100%', position: 'relative'}}>                    */}
                                <div 
                                  className='scrollBarPedido'
                                  style={
                                    tableVisible
                                      ? {
                                          height: '100%', width: '100%', position: 'absolute' ,
                                          transition: "0.5s ease-in-out",
                                          visibility: "visible",
                                          opacity: "1",
                                        }
                                      : { visibility: "hidden", opacity: "0" }
                                  }
                                >
                                  <ReactTable
                                    data={table}
                                    filterable={false}
                                    columns={[
                                      {
                                        Header: () => (<div style={{ textAlign: 'left', fontSize: '14px', color: '#444444' }}>#</div>),
                                        accessor: "checkboxItem",
                                        width: 30,
                                        sortable: false,
                                        filterable: false,
                                      },
                                      {
                                        Header: () => (<div style={{ textAlign: 'left', fontSize: '14px', color: '#444444' }}>FOTO</div>),
                                        accessor: "imagem",
                                        sortable: false,
                                        filterable: false,

                                      },
                                      {
                                        Header: () => (<div style={{ textAlign: 'left', fontSize: '14px', color: '#444444' }}>CÓDIGO</div>),
                                        accessor: "acessorCodigoProduto",
                                        minWidth: 80,
                                        sortable: false,
                                        filterable: false,
                                      },
                                      {
                                        Header: () => (<div style={{ textAlign: 'left', fontSize: '14px', color: '#444444' }}>DESCRIÇÃO</div>),
                                        accessor: "acessorDescricaoProduto",
                                        minWidth: 380,
                                        sortable: false,
                                        filterable: false,
                                      },
                                      {
                                        Header: () => (<div style={{ textAlign: 'center', fontSize: '14px', color: '#444444' }}>QUANT.</div>),
                                        accessor: "acessorQuantidade",
                                        minWidth: 60,
                                        resizable: false,
                                        sortable: false,        
                                      },
                                      {
                                        Header: () => (<div style={{ textAlign: 'right', fontSize: '14px', color: '#444444' }}>VALOR</div>),
                                        accessor: "acessorValorItem",
                                        minWidth: 120,
                                        resizable: false,
                                        sortable: false        
                                      },
                                      {
                                        Header: () => (<div style={{ textAlign: 'right', fontSize: '14px', color: '#444444' }}>VALOR TOTAL</div>),
                                        accessor: "acessorValorTotal",
                                        minWidth: 120,
                                        resizable: false,
                                        sortable: false        
                                      },
                                    {
                                        Header: "",
                                        accessor: "actions",
                                        sortable: false,
                                        filterable: false,
                                      },
                                    ]}
                                    showPaginationTop={false}
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                    noDataText=""
                                    pageSize={PEDIDOITENs.length}
                                  />
                                </div>
                            </TabPane>
                          </TabContent>
                      </Col>
                    </Row>
                  </CardBody>
                  {/* <hr style={ tema === 'ESCURO'? { borderColor: '#ffffff'} : { borderColor: '#727272'}  }/>   */}

                  <CardFooter style={{height: '110px', marginLeft: '0px', alignContent: 'center', textAlign: 'center'}}>
                    <Card style={{alignSelf: 'center', height: '100%', width: '100%', backgroundColor: "silver"}}>
                      <Row style={{ marginLeft: '0px', marginTop: '0px', width: '100%', backgroundColor: "silver"}}>
                                <Col lg="2" style={{marginLeft: '0px', marginTop: '20px', height: '65px'}}>
                                  <Card className="card-chart">
                                    <CardHeader>
                                      <h4 className="card-category">Itens</h4>
                                      <hr style={ tema === 'ESCURO'? { borderColor: '#ffffff'} : { borderColor: '#727272'}  }/>  
                                      <CardTitle tag="h4" textAlign="left">
                                        <i className="tim-icons icon-basket-simple text-info" style={{marginLeft: '-5px', color: "#0c59ce" }} />{""}
                                        <i style={{fontWeight: 'bold', fontStyle: 'normal' , color: "#0c59ce" }}>{PEDIDOITENs.length}</i>
                                      </CardTitle>
                                    </CardHeader>                                
                                  </Card>
                                </Col>

                                <Col lg="2" style={{marginLeft: '-24px', height: '65px', marginTop: '20px'}}>
                                <Card className="card-chart">
                                  <CardHeader>
                                    <h4 className="card-category">Sub Total</h4>
                                    <hr style={ tema === 'ESCURO'? { borderColor: '#ffffff'} : { borderColor: '#727272'}  }/>  
                                    <CardTitle tag="h4" textAlign="left">
                                      <i className="tim-icons icon-coins " style={{marginLeft: '-5px', color: "#f7a610" }} />{""}
                                      <i style={{fontWeight: 'bold', fontStyle: 'normal' , color: "#f7a610" }}>{pedidoPedido.valorSubTotal !== 0 ? pedidoPedido.valorSubTotal : maskMoeda(pedidoPedido.valorSubTotal.toFixed(2))}</i>
                                    </CardTitle>
                                  </CardHeader>                                
                                </Card>
                              </Col>

                              <Col lg="2" style={{marginLeft: '-24px' , height: '65px', marginTop: '20px'}}>
                                <Card className="card-chart">
                                  <CardHeader>
                                    <h4 className="card-category">Desconto</h4>
                                    <hr style={ tema === 'ESCURO'? { borderColor: '#ffffff'} : { borderColor: '#727272'}  }/>  
                                    <CardTitle tag="h4" textAlign="left">
                                      <i className="tim-icons icon-pencil" style={{marginLeft: '-5px', color: "#e71e1e" }} />{""}
                                      <i style={{fontWeight: 'bold', fontStyle: 'normal' , color: "#e71e1e" }}>0,00</i>
                                    </CardTitle>
                                  </CardHeader>                                
                                </Card>
                              </Col>

                              <Col lg="2" style={{marginLeft: '-23px' , height: '65px', marginTop: '20px'}}>
                                <Card className="card-chart">
                                  <CardHeader>
                                    <h4 className="card-category">Taxa Serviço</h4>
                                    <hr style={ tema === 'ESCURO'? { borderColor: '#ffffff'} : { borderColor: '#727272'}  }/>  
                                    <CardTitle tag="h4" textAlign="left">
                                      <i className="tim-icons icon-tie-bow text-info" style={{marginLeft: '-8px', color: "#0668c4" }} />{""}
                                      {/* <i style={{fontWeight: 'bold', fontStyle: 'normal' , color: "#0668c4" }}>{pedidoPedido.valorTaxa !== 0 ? pedidoPedido.valorTaxa: pedidoPedido.valorEntrega !== 0 ? pedidoPedido.valorEntrega : maskMoeda(pedidoPedido.valorTaxa.toFixed(2))}</i> */}
                                      <i style={{fontWeight: 'bold', fontStyle: 'normal' , color: "#0668c4" }}>{pedidoPedido.tipoVenda === 'E' ? 
                                                                                                                    pedidoPedido.valorEntrega !== 0 ? 
                                                                                                                      pedidoPedido.valorEntrega : 
                                                                                                                      maskMoeda(pedidoPedido.valorEntrega.toFixed(2))
                                                                                                                    :
                                                                                                                    pedidoPedido.valorTaxa !== 0 ? 
                                                                                                                      pedidoPedido.valorTaxa : 
                                                                                                                      maskMoeda(pedidoPedido.valorTaxa.toFixed(2))
                                                                                                                    }</i>
                                    </CardTitle>
                                  </CardHeader>                                
                                </Card>
                              </Col>

                              <Col lg="2" style={{marginLeft: '-23px' , height: '65px', marginTop: '20px'}}>
                                <Card className="card-chart">
                                  <CardHeader>
                                    <h4 className="card-category">Parcial</h4>
                                    <hr style={ tema === 'ESCURO'? { borderColor: '#ffffff'} : { borderColor: '#727272'}  }/>  
                                    <CardTitle tag="h4" textAlign="left">
                                      <i className="tim-icons icon-sound-wave" style={{marginLeft: '-8px', color: "#05b63a" }} />{""}
                                      <i style={{fontWeight: 'bold', fontStyle: 'normal' , color: "#05b63a" }}>{pedidoPedido.valorParcialTotal !== 0 ? pedidoPedido.valorParcialTotal: maskMoeda(pedidoPedido.valorParcialTotal.toFixed(2))}</i>
                                    </CardTitle>
                                  </CardHeader>                                
                                </Card>
                              </Col>

                              <Col lg="3" style={{marginLeft: '-20px' , height: '65px', marginTop: '20px'}}>
                                <Card className="card-chart">
                                  <CardHeader>
                                    <h4 className="card-category" style={{fontWeight: 'bold'}} >Total</h4>
                                    <hr style={ tema === 'ESCURO'? { borderColor: '#ffffff'} : { borderColor: '#727272'}  }/>  
                                    <CardTitle tag="h4" textAlign="left">
                                      <i className="tim-icons icon-coins" style={{ color: "#f7a610" }}/>{""}
                                      <i style={{fontWeight: 'bold', fontStyle: 'normal' , color: "#f7a610" }}>{pedidoPedido.valorTotalMenosParcial !== 0 ? pedidoPedido.valorTotalMenosParcial : maskMoeda(pedidoPedido.valorTotalMenosParcial.toFixed(2)) }</i>
                                      {/* <i style={{fontWeight: 'bold', fontStyle: 'normal' , color: "#f7a610" }}>{pedidoPedido.valorTotal !== 0 ? Number(pedidoPedido.valorTotal) - Number(pedidoCampos.valorParcialTotal) : maskMoeda(pedidoPedido.valorTotal.toFixed(2)) }</i> */}

                                      
                                    </CardTitle>
                                  </CardHeader>                                
                                </Card>
                              </Col>
                      </Row>            
                    </Card>  
                  </CardFooter>
                </Card>
              </Col>
            </Row>

          </Col>
      </div>

        {modalProdutos ?
          <ModalProdutos
            //modalProdutos={modalProdutos}
            isOpen={true}
            toggleModalProdutos={toggleModalProdutos}
            //paramProduto={cadastroProduto.idProduto}
            paramProduto={pesquisa.produto}
            shouldClean={shouldClean}
        />
        : null}

        {modalClientes ?
          <ModalClientes
            isOpen={true}
            toggleModalClientes={toggleModalClientes}
            paramCliente={pesquisaCliente.cliente}
            shouldClean={shouldClean}
        />
        : null}

        {modalPedidos ?
          <ModalPedidos
            isOpen={true}
            toggleModalPedidos={toggleModalPedidos}
            paramPedido={''}
            shouldClean={shouldClean}
        />
        : null}

        {modalItem ?
          <ModalItem
            isOpen={true}
            toggleModalItem={toggleModalItem}
            pedidoPedidoItem={pedidoPedidoItem}
            shouldClean={shouldClean}
        />
        : null}

        {modalRemoverItem ?
          <ModalRemoverItem
            isOpen={true}
            toggleModalRemoverItem={toggleModalRemoverItem}
            pedidoPedidoItem={pedidoPedidoItem}
            shouldClean={shouldClean}
        />
        : null}

        {modalFaturar ?
          <ModalFaturar
            isOpen={true}
            toggleModalFaturar={toggleModalFaturar}
            pedidoPedidoFaturar={pedidoPedidoFaturar}
            shouldClean={shouldClean}
        />
        : null}

        {modalTrocar ?
          <ModalTrocar
            isOpen={true}
            toggleModalTrocar={toggleModalTrocar}
            pedidoPedido={pedidoPedido}
            shouldClean={shouldClean}
        />
        : null}

        {modalReabrirExcluir ?
          <ModalReabrirExcluir
            isOpen={true}
            toggleModalReabrirExcluir={toggleModalReabrirExcluir}
            pedidoPedido={pedidoPedido}
            paramTela={paramTelaModal}
            shouldClean={shouldClean}
        />
        : null}

        {modalPgtoInformacao ?
          <ModalPgtoInformacao
            isOpen={true}
            toggleModalPgtoInformacao={toggleModalPgtoInformacao}
            pedidoPedidoFaturar={pedidoPedidoFaturar}
            shouldClean={shouldClean}
        />
        : null}

        {modalPgtoParcial ?
          <ModalPgtoParcial
            isOpen={true}
            toggleModalPgtoParcial={toggleModalPgtoParcial}
            pedidoPagamentoParcial={pedidoPagamentoParcial}
            shouldClean={shouldClean}
        />
        : null}

        {modalRetirada ?
          <ModalRetirada
            isOpen={true}
            toggleModalRetirada={toggleModalRetirada}
            caixaUsuarioRetiradas={caixaUsuarioRetiradas}
            shouldClean={shouldClean}
        />
        : null}

        {modalEndereco ?
          <ModalEndereco
            isOpen={true}
            toggleModalEndereco={toggleModalEndereco}
            pedidoCampos={pedidoCampos}
            shouldClean={shouldClean}
        />
        : null}

        {modalVendedor ?
          <ModalVendedor
            isOpen={true}
            toggleModalVendedor={toggleModalVendedor}
            shouldClean={shouldClean}
        />
        : null}

        {modalNFCe ?
          <ModalNFCe
            isOpen={true}
            toggleModalNFCe={toggleModalNFCe}
            NFCeCampos={NFCeCampos}
            paramTelaModalNFCe={paramTelaModalNFCe}
            shouldClean={shouldClean}
        />
        : null}

        {modalImprimir ?
          <ModalImprimir
            isOpen={true}
            toggleModalImprimir={toggleModalImprimir}
            titulo='Impressão de Cupom Fiscal NFC-e'
            mensagem='Deseja Imprimir ou Gerar Pdf do Cupom?'
            tipoRetlatorio='NFCE'
            objCampos={notaFiscalRetornoNFCe}
            shouldClean={shouldClean}
        />
        : null}


    </>


  );
};

/* class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      ////eslint-disable-next-line no-unused-vars
      //var descImg = document.getElementById("descricaoImg");
      //descImg = descImg && descImg.focus();
    }, 100);
  };

  render() {
    return <PedidoItem />;
  }
}

export default Wizard; */
export default PedidoItem;
