import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerConta from "~/controller/Cadastros/controllerConta"
import * as controllerContaGrupo from "~/controller/Cadastros/controllerContaGrupo"
import { Select } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip'; 
import { Switch } from "~/components/ErnComponets/ErnComponets";

import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup,
  CardTitle,
  CustomInput,
} from "reactstrap";

const tipoGrupo = [
  { value: "G", label: "Gastos (Débito)" },
  { value: "R", label: "Receitas (Crédito)" },
];


const idStore = "CONTA_GRUPO";

const ContaGrupoCadastro = () => {
  const notificacao = useSelector(state => state.notificacao);
  const login = useSelector((state) => state.login);
  const { cadastroContaGrupo, cadastroContaGrupoCopia, invalido } = useSelector((state) => state.ContaGrupo);
  const dispatch = useDispatch();
  const [isEnabled, setIsEnabled] = useState(false);

  //Carregar das uma vez
   useEffect(() => {
    handleStatus(true);
   }, [])
 

  function handleOnChange(value, campo) {
    dispatch({ idStore, type: "CONTA_GRUPO", campo: "idEmpresa", value: login.usuario.empresa }); 
    dispatch({ idStore, type: "CONTA_GRUPO", campo, value });
  }

  function handleStatus(campo){
    var statusConta = '';
    if (isEnabled){
      statusConta = '9';
      setIsEnabled(false);
    }else{
      statusConta = '1';
      setIsEnabled(true);
    }
    
    dispatch({ idStore, type: 'CONTA_GRUPO', campo: campo, value: statusConta })
  }
  
  async function Salvar(e) {
    e.preventDefault();
    if (cadastroContaGrupo.idContaGrupo === '') {
      controllerContaGrupo.CreateUpdateContaGrupo(notificacao, dispatch, cadastroContaGrupo, 'I', cadastroContaGrupoCopia);
    } else {
      controllerContaGrupo.CreateUpdateContaGrupo(notificacao, dispatch, cadastroContaGrupo, 'A', cadastroContaGrupoCopia);
    }
  };

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_CONTA_GRUPO' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    document.getElementById('descricaoContaGrupo').focus()
  };

  function onChangeCombo(value) {
    dispatch({ idStore, type: "CONTA_GRUPO", campo: "tipoGrupo", value });
  }


  return (
    <>
      <Row className="justify-content-center" style={{ marginTop: "-70px" }}>
        <Col>
          {/* <h4 className="info-text">Preencha com as informações do Usuário</h4> */}
          <CardBody>

            <Row className="justify-content-center">
              <Col md="10">
                <Row>

                  <Col className="padding-padrao-col" lg="2" md="2">
                    <label>Id Conta Grupo</label>
                      <Input
                        id="idContaGrupo"
                        type="text"
                        disabled
                        value={cadastroContaGrupo.idContaGrupo}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        autoComplete="off"
                      />
                      <br></br>
                  </Col>

                  <Col className="padding-padrao-col" lg="3" md="2">
                    <label>Tipo de Grupo</label>
                    <Select
                      id="tipoGrupoSelect"
                      value={{ value: cadastroContaGrupo.tipoGrupo }}
                      options={tipoGrupo}
                      onChange={(value) =>
                        onChangeCombo(value.value)
                      }  
                      autoComplete="off"
                    />
                  </Col>                  

                  <Col className="padding-padrao-col" lg="5" md="3">
                    <label>Descrição</label>
                    <FormGroup className={invalido.descricaoGrupo ? "has-danger" : ''}>
                      <Input
                        id="descricaoGrupo"
                        type="text"
                        value={cadastroContaGrupo.descricaoGrupo}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }    
                        autoComplete="off"
                        maxLength="40"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="text-center" md="2" >
                    <label>Status</label>
                    <p></p>
                      <Col md="10" className="text-right">
                        <Switch
                          componentleft={<label className={cadastroContaGrupo.status === "1" ? `text-success text-center` : "text-warning text-center"}>{cadastroContaGrupo.status === "1" ? "Ativo" : "Inativo"}</label>}
                          checked={cadastroContaGrupo.status === "1"}
                          onChange={(e) =>
                            handleStatus("status")
                          }
                          value={isEnabled}
                        />
                      </Col>            
                  </Col>
                </Row>

              </Col>

            </Row>

          </CardBody>
          <CardFooter className="text-center">
            <Row className="justify-content-center">
              <Col md="10" >
                <Col className="text-center">
                <ArrowTooltip title='shift+L'><Button className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Limpar</Button></ArrowTooltip>
                <ArrowTooltip title='shift+S'><Button className="btn-padrao" color="info" onClick={(e) => Salvar(e)}>Salvar</Button></ArrowTooltip>
                </Col>
              </Col>
            </Row>
          </CardFooter>
        </Col>
      </Row>





    </>
  )
};

//export default UsuarioCadastro;

 class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      document.getElementById('contaGrupoDescricao').focus()
    }, 100)
  }

  render() {
    return (
      <ContaGrupoCadastro />
    )
  }
}

export default Wizard;
