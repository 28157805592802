import axios from "axios"
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";


const idStore = 'CONTA_GRUPO';

export function Pesquisar(notificacao, dispatch, pesquisa, idEmpresa, thenFunction) {

  try {

    var ulrPesquisa = '';
    
    //VERIFICAR PESQUISA POR DESCRICAO

    ulrPesquisa = api.urlBaseChefe + api.urlCadastroContaGrupoGetGrupos + `${idEmpresa}` + `/${pesquisa.tipoGrupo}`;

    dispatch({ idStore, type: 'CONTAGRUPOs', value: [] })  

    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    let config = {
    method: 'GET',
    maxBodyLength: Infinity,
    url: ulrPesquisa,
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': bearerAuth
    },    
    };
    
    axios.request(config)
    .then((response) => {
    
      if (response.data.data.length === 0) {
          alertar(notificacao, 'br', "Nenhuma Conta Grupo encontrada", 'info', 'icon-bulb-63', 2);
      }

      var contas = response.data.data;  
      var totalContas = response.data.data.length;
      for (var i = 0; i < totalContas; i++) {
        if (contas[i].status === '1'){
          contas[i].status = 'ATIVO'
        }else {
          contas[i].status = 'INATIVO'
        }

        if (contas[i].tipoGrupo === 'G'){
          contas[i].tipoGrupo = "GASTOS";
        }else{
          contas[i].tipoGrupo = "RECEITA";
        }
      }

      dispatch({ idStore, type: 'CONTAGRUPOs', value: contas });

      thenFunction();

      })

  } catch (e) {
      alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
  }
}


export function RetornarContaGrupos(notificacao, dispatch, tipoGrupo, idEmpresa, thenFunction) {

  try {

    var ulrPesquisa = '';
    
    //VERIFICAR PESQUISA POR DESCRICAO

    ulrPesquisa = api.urlBaseChefe + api.urlCadastroContaGrupoGetGrupos + `${idEmpresa}` + `/${tipoGrupo}`;

    dispatch({ idStore, type: 'CONTAGRUPOs', value: [] })  

    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    let config = {
    method: 'GET',
    maxBodyLength: Infinity,
    url: ulrPesquisa,
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': bearerAuth
    },    
    };
    
    axios.request(config)
    .then((response) => {
    
      if (response.data.data.length === 0) {
          alertar(notificacao, 'br', "Nenhuma Conta Grupo encontrada", 'info', 'icon-bulb-63', 2);
      }

      var contas = response.data.data;  
      var totalContas = response.data.data.length;
      for (var i = 0; i < totalContas; i++) {
        if (contas[i].tipoGrupo === 'G'){
          contas[i].tipoGrupo = "GASTOS";
        }else{
          contas[i].tipoGrupo = "RECEITA";
        }

        contas[i].descricao = contas[i].descricao + ' - ' + contas[i].tipoGrupo        

      }

      dispatch({ idStore, type: 'CONTAGRUPOs', value: contas });

      thenFunction();

      })

  } catch (e) {
      alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
  }
}



/*-------------------------------------------------------------------------------------
* Utilizado para INCLUIR ou ALTERAR 
* OPERACAO = 'I' = INCLUIR / 'A' = ALTERACAO
*-------------------------------------------------------------------------------------*/
export async function CreateUpdateContaGrupo(notificacao, dispatch, cadastroContaGrupo, operacao, cadastroContaGrupoCopia) {
  try {
  
      let ulr = '';
      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
  
      ulr = api.urlBaseChefe + api.urlCadastroContaGrupoIncluirAlterar;
  
  
      if (JSON.stringify(cadastroContaGrupo) === JSON.stringify(cadastroContaGrupoCopia)) {
        alertar(notificacao, 'br', "Nenhuma Alteraçõe foi Feita no Cadastro.", 'info', 'icon-bulb-63', 2);
        return false
      }        
  
  
      if (operacao === 'I'){
        cadastroContaGrupo.idContaGrupo = "0";
      }
  
      var invalido = false;
      if (cadastroContaGrupo.descricaoGrupo === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'descricaoGrupo', value: true })
          invalido = true
      }
  
      if (invalido) {
          alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
          return true
      }
  
      return await axios({
        method: 'POST',
        url: ulr,
        headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
        data: {
            IdContaGrupo: cadastroContaGrupo.idContaGrupo,
            IdEmpresa: cadastroContaGrupo.idEmpresa,
            Descricao: cadastroContaGrupo.descricaoGrupo,
            TipoGrupo: cadastroContaGrupo.tipoGrupo,
            Status: cadastroContaGrupo.status,
        }
      }).then(response => {
          if (response.data.message === 'Sucesso'){
            alertar(notificacao, 'br', "Ação Realizada com SUCESSO.", 'success', 'icon-check-2', 2)
          }
  
          dispatch({ idStore, type: 'CLEAN_CONTA_GRUPO' });
          dispatch({ idStore, type: 'CLEAN_INVALIDO' });
  
          return false
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
  }
  
  export async function GetContaGrupo(notificacao, dispatch, idContaGrupo, thenFunction){
    try {
      
          var ulrPesquisa = '';
          ulrPesquisa = api.urlBaseChefe + api.urlCadastroContaGrupoGetContaGrupoId + `${idContaGrupo}`;
  
          const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
          let config = {
          method: 'GET',
          maxBodyLength: Infinity,
          url: ulrPesquisa,
          headers: { 
              'Authorization': bearerAuth
          }
          };
          
          axios.request(config)
          .then((response) => {
            if (response.data.message === 'Sucesso') {
              let type = 'CONTA_GRUPO';
              dispatch({ idStore, type, campo: 'idContaGrupo', value: response.data.data.idContaGrupo })
              dispatch({ idStore, type, campo: 'idEmpresa', value: response.data.data.idEmpresa })
              dispatch({ idStore, type, campo: 'descricaoGrupo', value: response.data.data.descricao })
              dispatch({ idStore, type, campo: 'tipoGrupo', value: response.data.data.tipoGrupo })
              dispatch({ idStore, type, campo: 'status', value: response.data.data.status })
      
              dispatch({ idStore, type: 'CONTA_GRUPO_COPIA' })

              thenFunction()
      
                
            }else{
              alertar(notificacao, 'br', "Nenhuma Conta Grupo encontrado", 'info', 'icon-bulb-63', 2)
            }
          })
      } catch (e) {
          alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
      }
  
  }
  
  /*-------------------------------------------------------------------------------------
  * Utilizado para ATIVAR/INATIVAR 
  * STATUS = 'A' = ATIVO ou 'I' = INATIVO
  *-------------------------------------------------------------------------------------*/
export async function AtivarInativar(notificacao, dispatch, idContaGrupo, statusContaGrupo, thenFunction) {
  try {
        var ulr = '';
        ulr = api.urlBaseChefe + api.urlCadastroContaGrupoAlterarStatus + `${idContaGrupo}` + "/" + `${statusContaGrupo}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: ulr,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === "Sucesso"){
            alertar(notificacao, 'br', "Conta Grupo Alterada com Sucesso.", 'success', 'icon-check-2', 2)
          }

          thenFunction()

          return false

      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
}
