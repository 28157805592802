import axios from "axios"
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";


const idStore = 'CONTA_SUBGRUPO';

export function Pesquisar(notificacao, dispatch, pesquisa, idEmpresa) {

  try {

    var ulrPesquisa = '';
    
    //VERIFICAR PESQUISA POR DESCRICAO

    ulrPesquisa = api.urlBaseChefe + api.urlCadastroContaSubGrupoGetSubGrupos + `${idEmpresa}` + `/${pesquisa.tipoGrupo}`;

    dispatch({ idStore, type: 'CONTASUBGRUPOs', value: [] })  

    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    let config = {
    method: 'GET',
    maxBodyLength: Infinity,
    url: ulrPesquisa,
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': bearerAuth
    },    
    };
    
    axios.request(config)
    .then((response) => {
    
      if (response.data.data.length === 0) {
          alertar(notificacao, 'br', "Nenhum Sub Grupo de Conta encontrada", 'info', 'icon-bulb-63', 2);
      }

      var subGrupo = response.data.data;  
      var totalContas = response.data.data.length;
      for (var i = 0; i < totalContas; i++) {
        if (subGrupo[i].status === '1'){
          subGrupo[i].status = 'ATIVO'
        }else {
          subGrupo[i].status = 'INATIVO'
        }

        if (subGrupo[i].tipoGrupo === 'G'){
          subGrupo[i].tipoGrupo = "GASTOS";
        }else{
          subGrupo[i].tipoGrupo = "RECEITA";
        }
      }

      dispatch({ idStore, type: 'CONTASUBGRUPOs', value: subGrupo });

      })

  } catch (e) {
      alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
  }
}


/*-------------------------------------------------------------------------------------
* Utilizado para INCLUIR ou ALTERAR 
* OPERACAO = 'I' = INCLUIR / 'A' = ALTERACAO
*-------------------------------------------------------------------------------------*/
export async function CreateUpdateContaSubGrupo(notificacao, dispatch, cadastroContaSubGrupo, operacao, cadastroContaSubGrupoCopia) {
  try {
  
      let ulr = '';
      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
  
      ulr = api.urlBaseChefe + api.urlCadastroContaSubGrupoIncluirAlterar;
  
  
      if (JSON.stringify(cadastroContaSubGrupo) === JSON.stringify(cadastroContaSubGrupoCopia)) {
        alertar(notificacao, 'br', "Nenhuma Alteraçõe foi Feita no Cadastro.", 'info', 'icon-bulb-63', 2);
        return false
      }        
  
  
      if (operacao === 'I'){
        cadastroContaSubGrupo.idContaSubGrupo = "0";
      }
  
      var invalido = false;
      if (cadastroContaSubGrupo.descricaoSubGrupo === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'descricaoSubGrupo', value: true })
          invalido = true
      }
  
      if (invalido) {
          alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
          return true
      }
  
      return await axios({
        method: 'POST',
        url: ulr,
        headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
        data: {
            IdContaSubGrupo: cadastroContaSubGrupo.idContaSubGrupo,
            IdContaGrupo: cadastroContaSubGrupo.idContaGrupo,
            Descricao: cadastroContaSubGrupo.descricaoSubGrupo,
            Status: cadastroContaSubGrupo.status,
        }
      }).then(response => {
          if (response.data.message === 'Sucesso'){
            alertar(notificacao, 'br', "Ação Realizada com SUCESSO.", 'success', 'icon-check-2', 2)
          }
  
          dispatch({ idStore, type: 'CLEAN_CONTA_SUBGRUPO' });
          dispatch({ idStore, type: 'CLEAN_INVALIDO' });
  
          return false
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
  }
  
  export async function GetContaSubGrupo(notificacao, dispatch, idContaSubGrupo, thenFunction){
    try {
      
          var ulrPesquisa = '';
          ulrPesquisa = api.urlBaseChefe + api.urlCadastroContaSugGrupoGetContaSubGrupoId + `${idContaSubGrupo}`;
  
          const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
          let config = {
          method: 'GET',
          maxBodyLength: Infinity,
          url: ulrPesquisa,
          headers: { 
              'Authorization': bearerAuth
          }
          };
          
          axios.request(config)
          .then((response) => {
            if (response.data.message === 'Sucesso') {
              let type = 'CONTA_SUBGRUPO';
              dispatch({ idStore, type, campo: 'idContaSubGrupo', value: response.data.data.idContaSubGrupo })
              dispatch({ idStore, type, campo: 'idContaGrupo', value: response.data.data.idContaGrupo })
              dispatch({ idStore, type, campo: 'descricaoSubGrupo', value: response.data.data.descricao })
              dispatch({ idStore, type, campo: 'status', value: response.data.data.status })
      
              dispatch({ idStore, type: 'CONTA_SUBGRUPO_COPIA' })

              thenFunction()
      
                
            }else{
              alertar(notificacao, 'br', "Nenhuma Sub Grupo de Conta encontrado", 'info', 'icon-bulb-63', 2)
            }
          })
      } catch (e) {
          alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
      }
  
  }
  
  /*-------------------------------------------------------------------------------------
  * Utilizado para ATIVAR/INATIVAR 
  * STATUS = 'A' = ATIVO ou 'I' = INATIVO
  *-------------------------------------------------------------------------------------*/
export async function AtivarInativar(notificacao, dispatch, idContaSubGrupo, statusContaSubGrupo, thenFunction) {
  try {
        var ulr = '';
        ulr = api.urlBaseChefe + api.urlCadastroContaSubGrupoAlterarStatus + `${idContaSubGrupo}` + "/" + `${statusContaSubGrupo}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: ulr,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === "Sucesso"){
            alertar(notificacao, 'br', "Sub Grupo de Conta Alterada com Sucesso.", 'success', 'icon-check-2', 2)
          }

          thenFunction()

          return false

      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
}
