import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import * as controllerLogin from "~/controller/controllerLogin.js";
//import ListEmpresa from "~/components/ListView/ListView";
import classnames from "classnames";
import "react-circular-progressbar/dist/styles.css";
import "react-notification-alert/dist/animate.css";
import useModal from '~/Util/Hooks/useModal.ts';

import ModalSenha from "./modalSenha.jsx";
import ModalLicenca from "./modalLicenca.jsx";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";

const avatar = "https://chefe.ernetwork.com.br/Img/logo_chefe_faster.png";
const idStore = "LOGIN";

const Login = () => {
  const login = useSelector((state) => state.login);
  const notificacao = useSelector((state) => state.notificacao);
  const [loginFocus, setLoginFocus] = useState(false);
  const [senhaFocus, setSenhaFocus] = useState(false);
  const dispatch = useDispatch();

  const [shouldClean, setshouldClean] = useState(false)
  const [paramTelaModal, setparamTelaModal] = useState({idMovimento: '', acaoTela: '', titulo: '', mensagem: '' })
  const [paramTelaModalLicenca, setparamTelaModalLicenca] = useState({nomeEmpresa: '', titulo: '', mensagem: '', mensagem2: '' })
  const [modalSenha, toggleModalSenha] = useModal(false)
  const [modalLicenca, toggleModalLicenca] = useModal(false)

  function handleModalEsqueceuSenha(){
    setparamTelaModal({ ...paramTelaModal, acaoTela: 'E', titulo: 'Recuperar Senha', mensagem: 'Receber um novo código de acesso' })
    toggleModalSenha();   
  }

  function handleModalAlterarSenha(){
    setparamTelaModal({ ...paramTelaModal, acaoTela: 'S', titulo: 'Alteração de Senha', mensagem: 'Cadastre uma nova senha' })
    toggleModalSenha();   
  }

  function handleModalLicenca(){
    var mensagem = 'Sua licença expirou';
    //var mensagem2 = 'Não se preocupe, você poderá solicitar UMA VEZ o acesso a plataforma hoje clicando no botão abaixo.';
    var mensagem2 = 'Renove sua licença em nosso site e volte a ter acesso a plataforma.';
    setparamTelaModalLicenca({ ...paramTelaModalLicenca, nomeEmpresa: login.usuario.nomeEmpresa, titulo: 'Renovação de Licença de Uso', mensagem: mensagem, mensagem2: mensagem2 })
    toggleModalLicenca();   
  }

  useEffect(() => {
    dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: '' })
    document.body.classList.toggle("login-page");
  }, []);

  async function handleKeyPress(event, type) {
    if (event.key === "Enter") {
      switch (type) {
        case "login":
          var alterarSenha = '';
          var qtdLicenca = 0;
          await controllerLogin.login(login, notificacao, dispatch, () => {
            //Alteração de senha
            alterarSenha = localStorage.getItem('ALTERAR_SENHA');
            qtdLicenca = localStorage.getItem('QTD_DIAS_LICENCA');
            if (alterarSenha === 'S'){ 
              handleModalAlterarSenha();
            }else if (qtdLicenca === 0){
              handleModalLicenca();
            }    
            // if (login.usuario.alterarSenha === 'S'){
            //   handleModalAlterarSenha();
            // }    
          });      
          //controllerLogin.login(login, notificacao, dispatch);
          break;
        case "novaSenha":
          controllerLogin.salvarSenha(login, notificacao, dispatch);
          break;
        default:
      }
    }
  }

  function handleOnChange(id, type, campo) {
    var value = document.getElementById(id).value;
    dispatch({ idStore, type, campo, value });
    dispatch({
      idStore,
      type: "OBJETOS",
      campo: "loginInvalido",
      value: false,
    });
    dispatch({
      idStore,
      type: "OBJETOS",
      campo: "senhaInvalida",
      value: false,
    });
    dispatch({
      idStore,
      type: "OBJETOS",
      campo: "novaSenhaInvalida",
      value: false,
    });
    dispatch({
      idStore,
      type: "OBJETOS",
      campo: "confirmarSenhaInvalida",
      value: false,
    });
  }

  async function handleEntrar(e) {
    e.preventDefault();
    var alterarSenha = '';
    var qtdLicenca = 0;
    await controllerLogin.login(login, notificacao, dispatch, () => {
      alterarSenha = localStorage.getItem('ALTERAR_SENHA');
      qtdLicenca = localStorage.getItem('QTD_DIAS_LICENCA');
      if (alterarSenha === 'S'){
        handleModalAlterarSenha();
      }else if (qtdLicenca < 0){
        handleModalLicenca();
      }    
    });


  }

/*   function handleInicioLogin() {
    controllerLogin.logout(dispatch);
    setTimeout(() => {
      document.getElementById("login").focus();
    // }, 300);
  }
 */
  return (
    <>
      <div className="content">
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form">
              <Card className="card-lock card-white text-center">
                <div id="conteudo">
                  <div className="bloco">
                    <div id="sombra"></div>
                  </div>
                  <div id="circle">
                    <div className="bloco">
                      <div id="foto1">
                        <img
                          alt="..."
                          className="foto"
                          id="avatar"
                          src={avatar}
/*                           style={
                            login.usuario.usuarioExiste &&
                            login.usuario.foto !== ""
                              ? { marginLeft: "-150px" }
                              : null
                          }
 */                        />
                      </div>
                    </div>

{/*                     <div className="bloco">
                      <div id="foto2">
                        <img
                          alt="..."
                          className="foto"
                          id="foto"
                          src={login.usuario.foto}
                          style={
                            login.usuario.usuarioExiste &&
                            login.usuario.foto !== ""
                              ? { marginLeft: "-300px" }
                              : null
                          }
                        />
                      </div>
                      <div className="bloco">
                        <div id="borda"></div>
                      </div>
                    </div>
 */}                    
                    <div className="bloco">
                      <CircularProgressbar
                        value={login.objetos.percentage1}
                        strokeWidth={4}
                        styles={buildStyles({
                          rotation: 0,
                          strokeLinecap: "round",
                          pathTransitionDuration: 0.5,
                          pathColor: "rgba(0, 180, 0, 1)",
                          trailColor: "rgba(0,0,0,0)",
                        })}
                      />
                    </div>
                    <div className="bloco">
                      <CircularProgressbar
                        value={login.objetos.percentage2}
                        strokeWidth={4}
                        styles={buildStyles({
                          rotation: 0,
                          strokeLinecap: "round",
                          pathTransitionDuration: 0.5,
                          //pathColor: "rgba(0,117,193,1)",
                          pathColor: "#f7a40c",
                          trailColor: "rgba(0,0,0,0)",
                        })}
                      />
                    </div>
                    <div className="bloco">
                      <CircularProgressbar
                        value={login.objetos.percentage3}
                        strokeWidth={4}
                        styles={buildStyles({
                          rotation: 0,
                          strokeLinecap: "round",
                          pathTransitionDuration: 0.5,
                          pathColor: "rgba(0, 180, 0, 1)",
                          trailColor: "rgba(0,0,0,0)",
                        })}
                      />
                    </div>
                  </div>
              </div>
                <CardBody>
                  <CardTitle tag="h4">Acesso</CardTitle>
                  <div
                    style={{
                      position: "relative",
                      minHeight: "15px",
                      marginTop: "15px",
                    }}
                  >
                    <InputGroup
                      className={classnames(
                        { "input-group-focus": loginFocus },
                        "transicaoLadoLadoIn",
/*                          !login.usuario.usuarioExiste
                          ? "transicaoLadoLadoIn"
                          : "transicaoLadoLadoOut",
 */                          
                        login.objetos.loginInvalido ? "has-danger" : "" 
                      )}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        style={{textTransform:"uppercase"}}
                        id="login"
                        placeholder="Login/Email"
                        type="text"
                        value={login.usuario.login}
                        onChange={() =>
                          handleOnChange("login", "USUARIO", "login")
                        }
                        autoFocus={true}
                        onKeyPress={(e) => handleKeyPress(e, "login")}
                        //disabled={!login.usuario.usuarioExiste ? false : true}
                        onFocus={() => setLoginFocus(true)}
                        onBlur={() => setLoginFocus(false)}
                        autoComplete="off"
                      />
                    </InputGroup>
                  </div>
                </CardBody>
                <CardBody>
                  <div
                    style={{
                      position: "relative",
                      minHeight: "15px",
                      marginTop: "15px",
                    }}
                  >
                    <InputGroup
                      className={classnames(
                        { "input-group-focus": senhaFocus },
                        "transicaoLadoLadoIn",
/*                          !login.usuario.usuarioExiste
                          ? "transicaoLadoLadoIn"
                          : "transicaoLadoLadoOut",
 */                          
                        login.objetos.senhaInvalida ? "has-danger" : ""
                      )}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="senha"
                        placeholder="Senha"
                        type="password"
                        value={login.usuario.senha}
                        onChange={() =>
                          handleOnChange("senha", "USUARIO", "senha")
                        }
                        onKeyPress={(e) => handleKeyPress(e, "login")}
                        onFocus={() => setSenhaFocus(true)}
                        onBlur={() => setSenhaFocus(false)}
                      />
                    </InputGroup>
                  </div>
                  <div
                      style={{
                        textAlign: 'right',
                        position: "relative",
                        minHeight: "15px",
                        marginTop: "30px",
                      }}                  
                  >
                    <a 
                      style={{color: '#189ffa', textDecoration: 'underline'}}
                      href="#"
                      className="pull-right"
                      onClick={(e) => handleModalEsqueceuSenha()}
                    >
                      <i className="tim-icons icon-key-25" /> Esqueceu sua senha?
                    </a>
                  </div>
                </CardBody>

                <CardFooter style={{ marginTop: "-15px" }}>
                  <Row className="justify-content-center">
                    <Button
                      className="btn-round btn-padrao group-btn-login"
                      color="default"
                      onClick={(e) => handleEntrar(e)}
                      style={
                        !login.usuario.loginSucesso 
                          ? {}
                          : { display: "disable" }
                          //: { display: "none" }
                      }
                    >
                      Entrar
                    </Button>
                  </Row>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>

      {modalSenha ?
          <ModalSenha
            isOpen={true}
            toggleModalSenha={toggleModalSenha}
            paramTela={paramTelaModal}
            shouldClean={shouldClean}
        />
      : null}

      {modalLicenca ?
          <ModalLicenca
            isOpen={true}
            toggleModalLicenca={toggleModalLicenca}
            paramTela={paramTelaModalLicenca}
            shouldClean={shouldClean}
        />
      : null}

    </>
  );
};

export default Login;
