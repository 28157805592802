import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerConta from "~/controller/Cadastros/controllerConta"
import * as controllerContaGrupo from "~/controller/Cadastros/controllerContaGrupo"
import * as controllerContaSubGrupo from "~/controller/Cadastros/controllerContaSubGrupo"
import { Select } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip'; 
import { Switch } from "~/components/ErnComponets/ErnComponets";

import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup,
  CardTitle,
  CustomInput,
} from "reactstrap";

const idStore = "CONTA_SUBGRUPO";

const ContaSubGrupoCadastro = () => {
  const notificacao = useSelector(state => state.notificacao);
  const login = useSelector((state) => state.login);
  const { cadastroContaSubGrupo, cadastroContaSubGrupoCopia, invalido } = useSelector((state) => state.ContaSubGrupo);
  const { CONTAGRUPOs } = useSelector((state) => state.ContaGrupo);
  const dispatch = useDispatch();
  const [isEnabled, setIsEnabled] = useState(false);

  //Carregar das uma vez
   useEffect(() => {
    handleStatus(true);
    handleContaGrupos();
   }, [])
 

  async function handleContaGrupos(){
    //CARREGA GRUPOS DE CONTA
    await controllerContaGrupo.RetornarContaGrupos(notificacao, dispatch, 'T', login.usuario.empresa, () => {
  
    });
  }

  function handleOnChange(value, campo) {
    dispatch({ idStore, type: "CONTA_SUBGRUPO", campo: "idEmpresa", value: login.usuario.empresa }); 
    dispatch({ idStore, type: "CONTA_SUBGRUPO", campo, value });
  }

  function handleStatus(campo){
    var statusConta = '';
    if (isEnabled){
      statusConta = '9';
      setIsEnabled(false);
    }else{
      statusConta = '1';
      setIsEnabled(true);
    }
    
    dispatch({ idStore, type: 'CONTA_SUBGRUPO', campo: campo, value: statusConta })
  }
  
  async function Salvar(e) {
    e.preventDefault();
    if (cadastroContaSubGrupo.idContaSubGrupo === '') {
      controllerContaSubGrupo.CreateUpdateContaSubGrupo(notificacao, dispatch, cadastroContaSubGrupo, 'I', cadastroContaSubGrupoCopia);
    } else {
      controllerContaSubGrupo.CreateUpdateContaSubGrupo(notificacao, dispatch, cadastroContaSubGrupo, 'A', cadastroContaSubGrupoCopia);
    }
    dispatch({ idStore, type: 'CONTA_SUBGRUPO', campo: 'status', value: '1' })
  };

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_CONTA_SUBGRUPO' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    document.getElementById('descricaoSubGrupo').focus()
  };

  function onChangeCombo(value) {
    dispatch({ idStore, type: "CONTA_SUBGRUPO", campo: "idContaGrupo", value });
  }


  return (
    <>
      <Row className="justify-content-center" style={{ marginTop: "-70px" }}>
        <Col>
          {/* <h4 className="info-text">Preencha com as informações do Usuário</h4> */}
          <CardBody>

            <Row className="justify-content-center">
              <Col md="10">
                <Row>

                  <Col className="padding-padrao-col" lg="2" md="2">
                    <label>Id SubGrupo</label>
                      <Input
                        id="idContaSubGrupo"
                        type="text"
                        disabled
                        value={cadastroContaSubGrupo.idContaSubGrupo}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        autoComplete="off"
                      />
                      <br></br>
                  </Col>

                  <Col className="padding-padrao-col" lg="4" md="3">
                    <label>Grupo de Conta</label>
                    <Select
                      id="grupoSelect"
                      select={{ value: "idContaGrupo", label: "descricao"  }}
                      first={"Selecione"}
                      value={{ value: cadastroContaSubGrupo.idContaGrupo }}
                      options={CONTAGRUPOs}
                      isSearchable={true}
                      onChange={(value) =>
                        onChangeCombo(value.value, "idContaGrupo")
                      }  
                      autoComplete="off"
                    />
                  </Col>

                  <Col className="padding-padrao-col" lg="4" md="3">
                    <label>Descrição</label>
                    <FormGroup className={invalido.descricaoSubGrupo ? "has-danger" : ''}>
                      <Input
                        id="descricaoSubGrupo"
                        type="text"
                        value={cadastroContaSubGrupo.descricaoSubGrupo}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }    
                        autoComplete="off"
                        maxLength="40"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="text-center" md="2" >
                    <label>Status</label>
                    <p></p>
                      <Col md="10" className="text-right">
                        <Switch
                          componentleft={<label className={cadastroContaSubGrupo.status === "1" ? `text-success text-center` : "text-warning text-center"}>{cadastroContaSubGrupo.status === "1" ? "Ativo" : "Inativo"}</label>}
                          checked={cadastroContaSubGrupo.status === "1"}
                          onChange={(e) =>
                            handleStatus("status")
                          }
                          value={isEnabled}
                        />
                      </Col>            
                  </Col>
                </Row>

              </Col>

            </Row>

          </CardBody>
          <CardFooter className="text-center">
            <Row className="justify-content-center">
              <Col md="10" >
                <Col className="text-center">
                <ArrowTooltip title='shift+L'><Button className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Limpar</Button></ArrowTooltip>
                <ArrowTooltip title='shift+S'><Button className="btn-padrao" color="info" onClick={(e) => Salvar(e)}>Salvar</Button></ArrowTooltip>
                </Col>
              </Col>
            </Row>
          </CardFooter>
        </Col>
      </Row>





    </>
  )
};

//export default UsuarioCadastro;

 class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      document.getElementById('contaGrupoDescricao').focus()
    }, 100)
  }

  render() {
    return (
      <ContaSubGrupoCadastro />
    )
  }
}

export default Wizard;
