//State da tela de Pesquisa e cadastro de Sub Grupo de Contas

import { faSlideshare } from "@fortawesome/free-brands-svg-icons"

var INITIAL_STATE = {

  pesquisaContaSubGrupo: {
      idContaSubGrupo: '',
      idEmpresa: '',
      descricaoSubGrupo: '',
      tipoGrupo: '',
  },

  cadastroContaSubGrupo: {
    idContaSubGrupo: '',
    idContaGrupo: '',
    descricaoSubGrupo: '',
    status: '',
  },

  invalido: {
    idContaGrupo: false,
    descricaoSubGrupo: false,
  },

  CONTASUBGRUPOs: [],
  inicioCadastro: false,
  cadastroContaSubGrupoCopia: {},
}

export default function CONTA_SUBGRUPO(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'CONTA_SUBGRUPO':
          switch (action.type) {
            case 'PESQUISA':
                return { ...state, pesquisaContaSubGrupo: { ...state.pesquisaContaSubGrupo, [action.campo]: action.value } }
            case 'CONTASUBGRUPOs':
                return { ...state, CONTASUBGRUPOs: action.value }
            case 'CLEAN_SEARCH':
                return {
                    ...state,
                    pesquisaContaSubGrupo: {
                      idContaSubGrupo: '',
                      idEmpresa: '',
                      descricaoSubGrupo: '',
                      tipoGrupo: '',                                                    
                    },
                    CONTASUBGRUPOs: []
                }
            case 'INVALIDO':
                return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
            case 'CONTA_SUBGRUPO':
                return { ...state, cadastroContaSubGrupo: { ...state.cadastroContaSubGrupo, [action.campo]: action.value } }
            case 'INICIO_CADASTRO':
                return { ...state, inicioCadastro: action.value }
            case 'CONTA_SUBGRUPO_COPIA':
                return { ...state, cadastroContaSubGrupoCopia: state.cadastroContaSubGrupoCopia }
            case 'CONTA_COPIA_STATUS':
                return { ...state, cadastroContaSubGrupoCopia: { ...state.cadastroContaSubGrupoCopia, status: action.value } }
            case 'CLEAN_CONTA_SUBGRUPO':
                return {
                    ...state,
                    cadastroContaSubGrupo: {
                      idContaSubGrupo: '',
                      idContaGrupo: '',
                      descricaoSubGrupo: '',
                      status: '',                                                                          
                    }
                }
            case 'CLEAN_INVALIDO':
                return {
                    ...state,
                    invalido: {
                        idContaGrupo: false,
                        descricaoSubGrupo: false,
                    }
                }
              default:
                  return { ...state }
          }
      default:
          return { ...state }
  }
}