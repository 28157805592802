import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
//import * as controllerGrupoTributacao from "~/controller/Cadastros/controllerGrupoTributacao"
import * as controllerConta from "~/controller/Cadastros/controllerConta"
//import { Select } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip'; 
import { Switch } from "~/components/ErnComponets/ErnComponets";



import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup,
  CardTitle,
  CustomInput,
} from "reactstrap";

const idStore = "CONTA";

const ContaCadastro = () => {
  const notificacao = useSelector(state => state.notificacao);
  const login = useSelector((state) => state.login);
  //const { cadastroGrupoTributacao, cadastroGrupoTributacaoCopia } = useSelector((state) => state.GrupoTributacao);
  const { cadastroConta, cadastroContaCopia, invalido } = useSelector((state) => state.Conta);
  const dispatch = useDispatch();
  const [isEnabled, setIsEnabled] = useState(false);
  const [isEnabledPrincipal, setIsEnabledPrincipal] = useState(false);
  const [isEnabledVenda, setIsEnabledVenda] = useState(false);
  const [isEnabledEntrada, setIsEnabledEntrada] = useState(false);
  const [isEnabledBaixa, setIsEnabledBaixa] = useState(false);

  //Carregar das uma vez
   useEffect(() => {
    handleStatus(true);
   }, [])
 

  function handleOnChange(value, campo) {
    dispatch({ idStore, type: "CONTA", campo: "idEmpresa", value: login.usuario.empresa }); 
    dispatch({ idStore, type: "CONTA", campo, value });
  }

  function handleStatus(campo){
    var statusConta = '';
    if (isEnabled){
      statusConta = '9';
      setIsEnabled(false);
    }else{
      statusConta = '1';
      setIsEnabled(true);
    }
    
    dispatch({ idStore, type: 'CONTA', campo: campo, value: statusConta })
  }

  function handlePrincipal(campo){
    var principal = '';
    if (isEnabledPrincipal){
      principal = 'N';
      setIsEnabledPrincipal(false);
    }else{
      principal = 'S';
      setIsEnabledPrincipal(true);
    }
    
    dispatch({ idStore, type: 'CONTA', campo: campo, value: principal })
  }

  function handleVenda(campo){
    var venda = '';
    if (isEnabledVenda){
      venda = 'N';
      setIsEnabledVenda(false);
    }else{
      venda = 'S';
      setIsEnabledVenda(true);
    }
    
    dispatch({ idStore, type: 'CONTA', campo: campo, value: venda })
  }

  function handleEntrada(campo){
    var entrada = '';
    if (isEnabledEntrada){
      entrada = 'N';
      setIsEnabledEntrada(false);
    }else{
      entrada = 'S';
      setIsEnabledEntrada(true);
    }
    
    dispatch({ idStore, type: 'CONTA', campo: campo, value: entrada })
  }

  function handleBaixa(campo){
    var baixa = '';
    if (isEnabledBaixa){
      baixa = 'N';
      setIsEnabledBaixa(false);
    }else{
      baixa = 'S';
      setIsEnabledBaixa(true);
    }
    
    dispatch({ idStore, type: 'CONTA', campo: campo, value: baixa })
  }
  
  async function Salvar(e) {
    e.preventDefault();
    if (cadastroConta.idConta === '') {
      controllerConta.CreateUpdateConta(notificacao, dispatch, cadastroConta, 'I', cadastroContaCopia);
    } else {
      controllerConta.CreateUpdateConta(notificacao, dispatch, cadastroConta, 'A', cadastroContaCopia);
    }
  };

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_CONTA' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    document.getElementById('descricaoConta').focus()
  };

  return (
    <>
      <Row className="justify-content-center" style={{ marginTop: "-70px" }}>
        <Col>
          {/* <h4 className="info-text">Preencha com as informações do Usuário</h4> */}
          <CardBody>

            <Row className="justify-content-center">
              <Col md="10">
                <Row>

                  <Col className="padding-padrao-col" lg="2" md="2">
                    <label>Id Conta</label>
                      <Input
                        id="idConta"
                        type="text"
                        disabled
                        value={cadastroConta.idConta}
                        //onChange={e => onChange(e.target.value, 'idUsuario')}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        autoComplete="off"
                      />
                      <br></br>
                  </Col>

                  <Col className="padding-padrao-col" lg="7" md="3">
                    <label>Descrição</label>
                    <FormGroup className={invalido.descricaoConta ? "has-danger" : ''}>
                      <Input
                        id="descricaoConta"
                        type="text"
                        value={cadastroConta.descricaoConta}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }    
                        autoComplete="off"
                        maxLength="30"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="text-center" md="2" >
                    <label>Status</label>
                    <p></p>
                      <Col md="10" className="text-right">
                        <Switch
                          componentleft={<label className={cadastroConta.status === "1" ? `text-success text-center` : "text-warning text-center"}>{cadastroConta.status === "1" ? "Ativo" : "Inativo"}</label>}
                          checked={cadastroConta.status === "1"}
                          onChange={(e) =>
                            handleStatus("status")
                          }
                          value={isEnabled}
                        />
                      </Col>            
                  </Col>
                </Row>

                <Row>
                  <Col className="text-left" lg="6" md="1" >
                    <Row style={{ marginTop: '-40px' }}>
                      <Col >
                        <p></p>
                        <Col  className="text-left">
                          <Switch
                            componentright={<label className={cadastroConta.principal === "S" ? `text-info text-center` : "text-warning text-center"}>{cadastroConta.principal === "S" ? "Conta Principal" : "Conta Principal"}</label>}
                            checked={cadastroConta.principal === "S"}
                            onChange={(e) =>
                              handlePrincipal("principal")
                            }
                            value={isEnabledPrincipal}
                          />
                        </Col>            
                      </Col>      
                    </Row>                      

                    <Row style={{ marginTop: '-20px' }}>
                      <Col >
                        <p></p>
                        <Col  className="text-left">
                          <Switch
                            componentright={<label className={cadastroConta.vendas === "S" ? `text-info text-center` : "text-warning text-center"}>{cadastroConta.vendas === "S" ? "Conta de Vendas" : "Conta de Vendas"}</label>}
                            checked={cadastroConta.vendas === "S"}
                            onChange={(e) =>
                              handleVenda("vendas")
                            }
                            value={isEnabledVenda}
                          />
                        </Col>            
                      </Col>      
                    </Row>                      

                    <Row style={{ marginTop: '-20px' }}>
                      <Col >
                        <p></p>
                        <Col  className="text-left">
                          <Switch
                            componentright={<label className={cadastroConta.entrada === "S" ? `text-info text-center` : "text-warning text-center"}>{cadastroConta.entrada === "S" ? "Conta de Entrada de Mercadorias" : "Conta de Entrada de Mercadorias"}</label>}
                            checked={cadastroConta.entrada === "S"}
                            onChange={(e) =>
                              handleEntrada("entrada")
                            }
                            value={isEnabledEntrada}
                          />
                        </Col>            
                      </Col>      
                    </Row>                      

                    <Row style={{ marginTop: '-20px' }}>
                      <Col >
                        <p></p>
                        <Col  className="text-left">
                          <Switch
                            componentright={<label className={cadastroConta.baixa === "S" ? `text-info text-center` : "text-warning text-center"}>{cadastroConta.baixa === "S" ? "Conta de Baixa e Recebimentos" : "Conta de Baixa e Recebimentos"}</label>}
                            checked={cadastroConta.baixa === "S"}
                            onChange={(e) =>
                              handleBaixa("baixa")
                            }
                            value={isEnabledBaixa}
                          />
                        </Col>            
                      </Col>      
                    </Row>                      

                  </Col>

                  
                </Row>        



              </Col>

            </Row>

          </CardBody>
          <CardFooter className="text-center">
            <Row className="justify-content-center">
              <Col md="10" >
                <Col className="text-center">
                <ArrowTooltip title='shift+L'><Button className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Limpar</Button></ArrowTooltip>
                <ArrowTooltip title='shift+S'><Button className="btn-padrao" color="info" onClick={(e) => Salvar(e)}>Salvar</Button></ArrowTooltip>
                </Col>
              </Col>
            </Row>
          </CardFooter>
        </Col>
      </Row>





    </>
  )
};

//export default UsuarioCadastro;

 class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      document.getElementById('contaDescricao').focus()
    }, 100)
  }

  render() {
    return (
      <ContaCadastro />
    )
  }
}

export default Wizard;
