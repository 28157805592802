import axios from "axios"
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";


const idStore = 'CONTA';

export function Pesquisar(notificacao, dispatch, pesquisa, idEmpresa) {

  try {

    var ulrPesquisa = '';
    
    //VERIFICAR PESQUISA POR DESCRICAO

    ulrPesquisa = api.urlBaseChefe + api.urlCadastroContaGetContas + `${idEmpresa}` + `/${pesquisa.statusConta}`;

    dispatch({ idStore, type: 'CONTAs', value: [] })  

    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    let config = {
    method: 'GET',
    maxBodyLength: Infinity,
    url: ulrPesquisa,
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': bearerAuth
    },    
    };
    
    axios.request(config)
    .then((response) => {
    
      if (response.data.data.length === 0) {
          alertar(notificacao, 'br', "Nenhuma Conta encontrada", 'info', 'icon-bulb-63', 2);
      }

      var contas = response.data.data;  
      var totalContas = response.data.data.length;
      for (var i = 0; i < totalContas; i++) {
        if (contas[i].status === '1'){
          contas[i].status = 'ATIVO'
        }else {
          contas[i].status = 'INATIVO'
        }

        if (contas[i].principal === 'S'){
          contas[i].principal = "SIM";
        }else{
          contas[i].principal = "NÃO";
        }

        if (contas[i].vendas === 'S'){
          contas[i].vendas = "SIM";
        }else{
          contas[i].vendas = "NÃO";
        }

        if (contas[i].entrada === 'S'){
          contas[i].entrada = "SIM";
        }else{
          contas[i].entrada = "NÃO";
        }

        if (contas[i].baixa === 'S'){
          contas[i].baixa = "SIM";
        }else{
          contas[i].baixa = "NÃO";
        }

      }

      dispatch({ idStore, type: 'CONTAs', value: contas });

      })

  } catch (e) {
      alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
  }
}


/*-------------------------------------------------------------------------------------
* Utilizado para INCLUIR ou ALTERAR 
* OPERACAO = 'I' = INCLUIR / 'A' = ALTERACAO
*-------------------------------------------------------------------------------------*/
export async function CreateUpdateConta(notificacao, dispatch, cadastroConta, operacao, cadastroContaCopia) {
  try {
  
      let ulr = '';
      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
  
      ulr = api.urlBaseChefe + api.urlCadastroContaIncluirAlterar;
  
  
      if (JSON.stringify(cadastroConta) === JSON.stringify(cadastroContaCopia)) {
        alertar(notificacao, 'br', "Nenhuma Alteraçõe foi Feita no Cadastro.", 'info', 'icon-bulb-63', 2);
        return false
      }        
  
  
      if (operacao === 'I'){
        cadastroConta.idConta = "0";
      }
  
      var invalido = false;
      if (cadastroConta.descricaoConta === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'descricaoConta', value: true })
          invalido = true
      }
  
      if (invalido) {
          alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
          return true
      }

      if (cadastroConta.princpal === ''){
        cadastroConta.principal = 'N';    
      }
      if (cadastroConta.vendas === ''){
        cadastroConta.vendas = 'N';    
      }
      if (cadastroConta.entrada === ''){
        cadastroConta.entrada = 'N';    
      }
      if (cadastroConta.baixa === ''){
        cadastroConta.baixa = 'N';    
      }
  
      return await axios({
        method: 'POST',
        url: ulr,
        headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
        data: {
            IdConta: cadastroConta.idConta,
            IdEmpresa: cadastroConta.idEmpresa,
            Descricao: cadastroConta.descricaoConta,
            Principal: cadastroConta.principal,
            Vendas: cadastroConta.vendas,
            Entrada: cadastroConta.entrada,
            Baixa: cadastroConta.baixa,
            Status: cadastroConta.status,
        }
      }).then(response => {
          if (response.data.message === 'Sucesso'){
            alertar(notificacao, 'br', "Ação Realizada com SUCESSO.", 'success', 'icon-check-2', 2)
          }
  
          dispatch({ idStore, type: 'CLEAN_CONTA' });
          dispatch({ idStore, type: 'CLEAN_INVALIDO' });
  
          return false
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
  }
  
  export async function GetConta(notificacao, dispatch, idConta, thenFunction){
    try {
      
          var ulrPesquisa = '';
          ulrPesquisa = api.urlBaseChefe + api.urlCadastroContaGetContaId + `${idConta}`;
  
          const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
          let config = {
          method: 'GET',
          maxBodyLength: Infinity,
          url: ulrPesquisa,
          headers: { 
              'Authorization': bearerAuth
          }
          };
          
          axios.request(config)
          .then((response) => {
            if (response.data.message === 'Sucesso') {
              let type = 'CONTA';
              dispatch({ idStore, type, campo: 'idConta', value: response.data.data.idConta })
              dispatch({ idStore, type, campo: 'idEmpresa', value: response.data.data.idEmpresa })
              dispatch({ idStore, type, campo: 'descricaoConta', value: response.data.data.descricao })
              dispatch({ idStore, type, campo: 'principal', value: response.data.data.principal })
              dispatch({ idStore, type, campo: 'vendas', value: response.data.data.vendas })
              dispatch({ idStore, type, campo: 'entrada', value: response.data.data.entrada })
              dispatch({ idStore, type, campo: 'baixa', value: response.data.data.baixa })
              dispatch({ idStore, type, campo: 'status', value: response.data.data.status })
      
              dispatch({ idStore, type: 'CONTA_COPIA' })

              thenFunction()
      
                
            }else{
              alertar(notificacao, 'br', "Nenhuma Conta encontrado", 'info', 'icon-bulb-63', 2)
            }
          })
      } catch (e) {
          alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
      }
  
  }
  
  /*-------------------------------------------------------------------------------------
* Utilizado para ATIVAR/INATIVAR 
* STATUS = 'A' = ATIVO ou 'I' = INATIVO
*-------------------------------------------------------------------------------------*/
export async function AtivarInativar(notificacao, dispatch, idConta, statusConta, thenFunction) {
  try {
        var ulr = '';
        ulr = api.urlBaseChefe + api.urlCadastroContaAlterarStatus + `${idConta}` + "/" + `${statusConta}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: ulr,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === "Sucesso"){
            alertar(notificacao, 'br', "Conta Alterada com Sucesso.", 'success', 'icon-check-2', 2)
          }
          //var user = response.data.data;
          //dispatch({ idStore, type: 'USUARIO_LOGIN', campo: 'idUsuario', value: user.IdUsuarioLogin })
          //dispatch({ idStore, type: 'USUARIO_LOGIN_COPIA' })

          thenFunction()

          return false

        //dispatch({ idStore, type: 'USUARIOS', value: response.data.data })
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
}
