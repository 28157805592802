import React, { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import * as controllerPedidoVendas from "~/controller/Vendas/controllerPedidoVendas"
import '~/views/Vendas/Pedido/Modals/styles.css'
import { maskNumbers } from "~/Util/mascaras";

import {
    Input,
    Row,
    Col,
    Button,
} from "reactstrap";


const ModalNFCe = ({isOpen, toggleModalNFCe, NFCeCampos, paramTelaModalNFCe, shouldClean}) => {
    const inputRef = useRef(null);

    const login = useSelector((state) => state.login);
    const notificacao = useSelector((state) => state.notificacao);
    const [loading, setLoading] = useState(false);

    const { notaFiscalRetornoNFCe } = useSelector((state) => state.PedidoItem);


    const [usuarioPedido, setusuarioPedido] = useState({idEmpresa: '', idUsuario: '', idPedido: '', idNotaFiscal: '', justificativa: '', cpfCnpjCliente: '', nomeCliente: ''})
    const dispatch = useDispatch();

    useEffect(() => {
      if (NFCeCampos !== null){
        setusuarioPedido({ ...usuarioPedido, idEmpresa: NFCeCampos.idEmpresa,
                                             idUsuario: NFCeCampos.idUsuario,
                                             idPedido: NFCeCampos.idPedido,
                                             idNotaFiscal: NFCeCampos.idNotaFiscal,
                                             justificativa: '',
                                             cpfCnpjCliente: '',
                                             nomeCliente: '***CONSUMIDOR***',
         })
      }
      if (inputRef.current) {
        inputRef.current.focus();
      } 
    }, [])
 
    function handleVoltar(){
      toggleModalNFCe();
    }

    //--------------------------------------------------------------
    //I = Incluir Cupom Fiscal NFCe
    //C = Cancelar Cupom Fiscal NFCe
    //--------------------------------------------------------------
    async function handleOnclick(){
       if (paramTelaModalNFCe.acaoTela === 'I'){
        handleGerarNFCE();    
       }else if (paramTelaModalNFCe.acaoTela === 'C'){
         
       }
    }

    async function handleGerarNFCE(mensagem){
      dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: 'Emitindo NFC-e Aguarde...' })
      setLoading(true);
      if (!loading) {
        await controllerPedidoVendas.GerarNFCe(notificacao, dispatch, usuarioPedido, () => {
            
        });  
      }
      setLoading(false);
      toggleModalNFCe();
    }

    function handleOnChange(value, campo) {
      setusuarioPedido({ ...usuarioPedido, justificativa: value }) 
    }

    function handleOnChangeCpfCnpj(value, campo) {
      setusuarioPedido({ ...usuarioPedido, cpfCnpjCliente: value }) 
    }
  
    function handleOnChangeNome(value, campo) {
      setusuarioPedido({ ...usuarioPedido, nomeCliente: value }) 
    }

    return (
          <ModalBasic
            id="modalItem"
            isOpen={isOpen}
            toggle={() => toggleModalNFCe()}
            fontSize='30px'
            title={paramTelaModalNFCe.titulo}
            size="md" // sm, md, lg, xl = TAMANHO da Modal
          > 
            <ModalBasicBody>
                <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col" style={paramTelaModalNFCe.acaoTela === 'C' ? {display: 'none'} : {textAlign:'left' }}>
                    <label>{paramTelaModalNFCe.acaoTela === 'I' ? 'Deseja Emitir Cupom Fiscal ?': 'Informe a Justificativa com no minimo 15 caracteres.'}</label>
                  </Col>
                </Row>
                <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col" style={paramTelaModalNFCe.acaoTela === 'I' ? {display: 'none'} : {textAlign:'left' }}>
                    <label
                    style={paramTelaModalNFCe.acaoTela === 'I' ? {display: 'none'} : {textAlign:'left' }}
                    >Justificativa</label>
                      <Input
                          ref={inputRef} 
                          style={paramTelaModalNFCe.acaoTela === 'I' ? {display: 'none'} : {textAlign:'center', textTransform:"uppercase" }}
                          id='justificativa'
                          type='text'
                          autoComplete='off'
                          value={usuarioPedido.justificativa}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          onFocus={e => e.target.select()}
                          maxLength="15"
                      />
                  </Col>                  

                  <Col className="padding-padrao-col">
                    <label>Cpf/Cnpj</label>
                      <Input
                          ref={inputRef} 
                          style={paramTelaModalNFCe.acaoTela === 'C' ? {display: 'none'} : {textAlign:'center', textTransform:"uppercase" }}
                          id='cpfCnpj'
                          type='text'
                          autoComplete='off'
                          value={usuarioPedido.cpfCnpjCliente}
                          onChange={(e) =>
                            handleOnChangeCpfCnpj(e.target.value, e.target.id)
                          }
                          onFocus={e => e.target.select()}
                          maxLength="15"
                      />
                  </Col>                  
                  <Col className="padding-padrao-col">
                    <label>Nome Cliente</label>
                    <Input
                          ref={inputRef} 
                          style={paramTelaModalNFCe.acaoTela === 'C' ? {display: 'none'} : {textAlign:'center', textTransform:"uppercase" }}
                          id='nomeCliente'
                          type='text'
                          autoComplete='off'
                          value={usuarioPedido.nomeCliente}
                          onChange={(e) =>
                            handleOnChangeNome(e.target.value, e.target.id)
                          }
                          onFocus={e => e.target.select()}
                          maxLength="25"
                      />
                  </Col>                  

                </Row>    
            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                className='btn btn-green'
                                //color="info"
                                type="button"
                                onClick={handleVoltar}
                                style={{ width: '100px' }}
                            >Não
                            </Button>
                            <Button
                                color="info"
                                type="button"
                                onClick={() => handleOnclick()}
                                style={{ width: '100px' }}
                            >Sim
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>
          
        </ModalBasic>
      
    )
}

export default ModalNFCe