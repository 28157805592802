import React, { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import '~/views/Vendas/Pedido/Modals/styles.css'
import { alertar } from "~/Util/alertar";
import { maskNumbers } from "~/Util/mascaras";

//Imports para impressão/geração de PDF
import * as controllerPedidoVendas from "~/controller/Vendas/controllerPedidoVendas"
import {pdfCupomNFCe} from "~/pdf/Vendas/Pedido/pdfCupomNFCe";


import {
    Input,
    Row,
    Col,
    Button,
} from "reactstrap";

// -------------------------------------------------------------------------------
// Model de Impressão/Visualização
// titulo = Titulo da Modal
// mensagem = Mensagem a mostrar na modal (Ex: Imprimir ou Visualizar NFC-e)
// relatorio = Nome do Relatorio
// objCampos = Campos para buscar informações do retorio (ID)
// -------------------------------------------------------------------------------
const ModalImprimir = ({isOpen, toggleModalImprimir, titulo, mensagem, tipoRetlatorio, objCampos, shouldClean}) => {
    const inputRef = useRef(null);

    const login = useSelector((state) => state.login);
    const notificacao = useSelector((state) => state.notificacao);
    const [loading, setLoading] = useState(false);
    const { notaFiscalNFCe } = useSelector((state) => state.PedidoItem);

    //const [mesaComandaNova, setmesaComandaNova] = useState({idPedido: '', idEmpresa: '', mesaComandaNova: '' })
    const dispatch = useDispatch();

    useEffect(() => { 
    }, [])
 
    function handleVoltar(){
      toggleModalImprimir();
    }

    //Visualizar/Gerar Pdf (tipo = V = Visualizar/ G = Gerar Pdf)
    async function handleVisualizarGerarPdf(tipo) {

      dispatch({ idStore: 'MENSAGEMLOADBAR', type: 'MENSAGEMLOADBAR', campo: 'mensagem', value: 'Carregando...' })
      setLoading(true);
      if (!loading) {
        if (tipoRetlatorio === 'NFCE'){
          pdfCupomNFCe(login, tipo, notaFiscalNFCe);      
        }
      }
      setLoading(false);
      toggleModalImprimir();
    };

    function handleOnChange(value, campo) {
      //setmesaComandaNova({ ...mesaComandaNova, mesaComandaNova: value }) 
    }
  

    return (
          <ModalBasic
            id="modalItem"
            isOpen={isOpen}
            toggle={() => toggleModalImprimir()}
            title={titulo}
            size="md" // sm, md, lg, xl = TAMANHO da Modal
          > 
            <ModalBasicBody>
              <Row className="padding-padrao-row">
                <Col className="padding-padrao-col">
                  <label
                    style={{fontSize: '16px', textAlign:'center' }}
                  >{mensagem}</label>
                </Col>
              </Row>        
                {/* <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col">
                    <label>De</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '30px', height: '60px', textAlign:'center' }}
                          id='mesaDe'
                          type='text'
                          autoComplete='off'
                          value={pedidoPedido.mesaComanda}
                          disabled
                      />
                  </Col>
                  <Col className="padding-padrao-col">
                    <label>Para</label>
                      <Input
                          ref={inputRef} 
                          style={{textTransform:"uppercase" , fontSize: '30px', height: '60px', textAlign:'center' }}
                          id='mesaPara'
                          type='text'
                          autoComplete='off'
                          value={pedidoPedido.produtoDescricao}
                          onChange={(e) =>
                            handleOnChange(maskNumbers(e.target.value), e.target.id)
                          }
                          onFocus={e => e.target.select()}
                          maxLength="4"
                      />
                  </Col>

                </Row>       */}

            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                className='btn btn-green'
                                //color="info"
                                type="button"
                                onClick={handleVoltar}
                                style={{ width: '100px' }}
                            >Voltar
                            </Button>
                            <Button
                                color="info"
                                type="button"
                                onClick={() => handleVisualizarGerarPdf('V')}
                                style={{ width: '100px' }}
                            >Visualizar
                            </Button>
                            <Button
                                color="info"
                                type="button"
                                onClick={() => handleVisualizarGerarPdf('G')}
                                style={{ width: '100px' }}
                            >Gerar PDF
                            </Button>

                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>
          
        </ModalBasic>
      
    )
}

export default ModalImprimir